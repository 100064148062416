
export default [
    {
        "id": 1,
        "shikenId": 1,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "ファイナンシャル・プランナー（以下「FP」という）の顧客に対する行為に関する次の記述のうち、関連法規に照らし、最も不適切なものはどれか。[2023.09]",
        "choice1": "金融商品取引業の登録を受けていないFPのAさんは、顧客と資産運用に関する投資顧問契約を締結したうえで、値上がりが期待できる株式の個別銘柄の購入を勧めた。",
        "choice2": "弁護士の登録を受けていないFPのBさんは、財産管理の相談に来た顧客の求めに応じ、有償で、当該顧客を委任者とする任意後見契約の受任者となった。",
        "choice3": "税理士の登録を受けていないFPのCさんは、顧客から配偶者控除と配偶者特別控除の適用要件を聞かれ、所得税法の条文等を示しつつ、それぞれの適用要件の違いを説明した。",
        "choice4": "生命保険募集人の登録を受けていないFPのDさんは、顧客からライフプランの相談を受け、老後資金を準備するための生命保険の一般的な活用方法を説明した。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "FPと関連法規",
        "important": ""
    },
    {
        "id": 2,
        "shikenId": 1,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "ファイナンシャル・プランナーがライフプランニングに当たって作成するキャッシュフロー表の一般的な作成方法に関する次の記述のうち、最も適切なものはどれか。[2023.01]",
        "choice1": "キャッシュフロー表の作成において、可処分所得は、年間の収入金額から直接税、社会保険料および住居費の金額を控除した金額を計上する。",
        "choice2": "キャッシュフロー表の作成において、住宅ローンの返済方法を元金均等返済方式とした場合、その返済額は、毎年同額を計上する。",
        "choice3": "キャッシュフロー表の作成において、基本生活費や教育費等の支出項目に計上した金額は、家族構成が変わらない限り、見直す必要はない。",
        "choice4": "キャッシュフロー表の作成において、各年次の貯蓄残高は、「前年末の貯蓄残高×(1＋運用利率)＋当年の年間収支」の算式で計算した金額を計上する。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "キャッシュフロー表の作成方法",
        "important": ""
    },
    {
        "id": 3,
        "shikenId": 1,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "労働者災害補償保険（以下「労災保険」という）に関する次の記述のうち、最も適切なものはどれか。[2022.05]",
        "choice1": "労災保険の保険料を計算する際に用いる労災保険率は、常時使用する従業員数に応じて定められている。",
        "choice2": "労働者が業務上の負傷または疾病による療養のために労働することができず、賃金の支給を受けられない場合、賃金の支給を受けられない日の1日目から休業補償給付が支給される。",
        "choice3": "労働者が業務上の負傷または疾病により、労災指定病院で療養補償給付として受ける療養の給付については、労働者の一部負担金はない。",
        "choice4": "労働者が業務上の負傷または疾病が治癒したときに一定の障害が残り、その障害の程度が所定の障害等級に該当するときは、障害補償年金または障害補償一時金のいずれかを選択して受給することができる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "労災保険",
        "important": ""
    },
    {
        "id": 4,
        "shikenId": 1,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "雇用保険に関する次の記述のうち、最も不適切なものはどれか。[2024.05]",
        "choice1": "雇用保険に係る保険料のうち、失業等給付および育児休業給付に係る保険料は、事業主と労働者が折半して負担する。",
        "choice2": "雇用保険の適用事業所に雇用される労働者であって、所定の要件を満たす者は、日本国籍の有無にかかわらず、雇用保険の被保険者となる。",
        "choice3": "特定受給資格者等を除く一般の受給資格者に支給される基本手当の所定給付日数は、算定基礎期間が10年以上20年未満の場合、150日である。",
        "choice4": "一般被保険者が失業した場合、基本手当の支給を受けるためには、原則として、離職の日以前2年間に被保険者期間が通算して12ヵ月以上あること等の要件を満たす必要がある。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "雇用保険",
        "important": ""
    },
    {
        "id": 5,
        "shikenId": 1,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "国民年金の保険料に関する次の記述のうち、最も不適切なものはどれか。[2022.09]",
        "choice1": "国民年金の付加保険料は、将来の一定期間の保険料を前納することができ、前納する期間に応じて所定の額が控除される。",
        "choice2": "第1号被保険者で障害基礎年金または障害等級1級もしくは2級の障害厚生年金を受給している者は、原則として、所定の届出により、保険料の納付が免除される。",
        "choice3": "第1号被保険者が出産する場合、所定の届出により、出産予定月の前月から4ヵ月間（多胎妊娠の場合は出産予定月の3ヵ月前から6ヵ月間）、保険料の納付が免除される。",
        "choice4": "保険料免除期間に係る保険料を追納する場合、追納保険料は、追納する時期にかかわらず、免除された時点における保険料の額となる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "国民年金の保険料",
        "important": ""
    },
    {
        "id": 6,
        "shikenId": 1,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "公的年金等に係る税金に関する次の記述のうち、最も不適切なものはどれか。なお、記載のない事項については考慮しないものとする。[2022.09]",
        "choice1": "老齢基礎年金および老齢厚生年金は、その年中に受け取る当該年金の収入金額から公的年金等控除額を控除した金額が一時所得として所得税の課税対象となる。",
        "choice2": "障害基礎年金および障害厚生年金は、所得税の非課税所得となる。",
        "choice3": "老齢基礎年金および老齢厚生年金の受給者が死亡した場合において、その者に支給されるべき年金給付のうち、まだ支給されていなかったもの（未支給年金）は、当該年金を受け取った遺族の一時所得として所得税の課税対象となる。",
        "choice4": "国民年金の保険料および国民年金基金の掛金は、いずれも社会保険料控除として所得税の所得控除の対象となる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "公的年金の税金",
        "important": ""
    },
    {
        "id": 7,
        "shikenId": 1,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "公的年金制度の障害給付および遺族給付に関する次の記述のうち、最も不適切なものはどれか。[2024.01]",
        "choice1": "障害等級1級または2級に該当する程度の障害の状態にある障害厚生年金の受給権者が、所定の要件を満たす配偶者を有する場合、その受給権者に支給される障害厚生年金には加給年金額が加算される。",
        "choice2": "障害厚生年金の額を計算する際に、その計算の基礎となる被保険者期間の月数が300月に満たない場合、300月として計算する。",
        "choice3": "遺族基礎年金を受給することができる遺族は、国民年金の被保険者等の死亡の当時、その者によって生計を維持され、かつ、所定の要件を満たす「子のある配偶者」または「子」である。",
        "choice4": "遺族厚生年金の受給権者が、65歳到達日に老齢厚生年金の受給権を取得した場合、65歳以降、その者の選択によりいずれか一方の年金が支給され、他方の年金は支給停止となる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "障害給付と遺族給付",
        "important": ""
    },
    {
        "id": 8,
        "shikenId": 1,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "確定拠出年金に関する次の記述のうち、最も不適切なものはどれか。[2023.01]",
        "choice1": "企業型年金を実施する事業主は、企業型年金規約において、加入者に一定の資格を定めることができる。",
        "choice2": "企業型年金における加入者掛金（マッチング拠出により加入者が拠出する掛金）の上限額は、事業主掛金の額にかかわらず、拠出限度額から当該加入者に係る事業主掛金の額を差し引いた額となる。",
        "choice3": "企業型年金の掛金は、月単位での拠出のほか、賞与時期のみの拠出や年1回の拠出も可能である。",
        "choice4": "企業型年金や確定給付企業年金等を実施していない一定規模以下の中小企業の事業主は、労使の合意かつ従業員の同意を基に、従業員が加入している個人型年金の加入者掛金に一定額の事業主掛金を上乗せして納付することができる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "確定拠出年金",
        "important": ""
    },
    {
        "id": 9,
        "shikenId": 1,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "決算書に基づく経営分析指標に関する次の記述のうち、最も適切なものはどれか。[2022.05]",
        "choice1": "損益分岐点比率は、実際の売上高に対する損益分岐点売上高の割合を示したものであり、一般に、この数値が低い方が企業の収益性が高いと判断される。",
        "choice2": "自己資本比率は、総資本に対する自己資本の割合を示したものであり、一般に、この数値が低い方が財務の健全性が高いと判断される。",
        "choice3": "固定長期適合率は、自己資本に対する固定資産の割合を示したものであり、一般に、この数値が低い方が財務の健全性が高いと判断される。",
        "choice4": "ROEは、自己資本に対する当期純利益の割合を示したものであり、一般に、この数値が低い方が経営の効率性が高いと判断される。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "決算書に基づく経営分析指標",
        "important": ""
    },
    {
        "id": 10,
        "shikenId": 1,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "クレジットカード会社（貸金業者）が発行するクレジットカードの一般的な利用に関する次の記述のうち、最も不適切なものはどれか。[2024.01]",
        "choice1": "クレジットカードで商品を購入（ショッピング）した場合の返済方法の一つである定額リボルビング払い方式は、カード利用時に代金の支払回数を決め、利用代金をその回数で分割して支払う方法である。",
        "choice2": "クレジットカードで無担保借入（キャッシング）をする行為は、貸金業法上、総量規制の対象となる。",
        "choice3": "クレジットカード会員規約では、クレジットカードは他人へ貸与することが禁止されており、クレジットカード会員が生計を維持している親族に対しても貸与することはできない。",
        "choice4": "クレジットカード会員の信用情報は、クレジットカード会社が加盟する指定信用情報機関により管理されており、会員は自己の信用情報について所定の手続きにより開示請求をすることができる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "クレジットカード",
        "important": ""
    },
    {
        "id": 11,
        "shikenId": 1,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "生命保険の保険料等の一般的な仕組みに関する次の記述のうち、最も不適切なものはどれか。[2023.05]",
        "choice1": "収支相等の原則は、保険会社が受け取る保険料等の総額が、保険会社が支払う保険金等の総額と等しくなるように保険料を算定する原則をいう。",
        "choice2": "保険料のうち、将来の保険金等の支払財源となる純保険料は、予定死亡率に基づいて計算され、保険会社が保険契約を維持・管理していくために必要な経費等の財源となる付加保険料は、予定利率および予定事業費率に基づいて計算される。",
        "choice3": "終身保険について、保険料の算定に用いられる予定利率が引き上げられた場合、新規契約の保険料は安くなる。",
        "choice4": "保険会社が実際に要した事業費が、保険料を算定する際に見込んでいた事業費よりも少なかった場合、費差益が生じる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険の保険料等の一般的な仕組み",
        "important": ""
    },
    {
        "id": 12,
        "shikenId": 1,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "死亡保障を目的とする生命保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。なお、特約については考慮しないものとする。[2022.05]",
        "choice1": "定期保険では、保険期間中に所定の支払事由が発生すると、死亡保険金や高度障害保険金が支払われるが、保険期間満了時に満期保険金は支払われない。",
        "choice2": "終身保険では、保険料払込期間が有期払いの場合と終身払いの場合を比較すると、他の契約条件が同一であれば、年払いの1回当たりの払込保険料は終身払いの方が高い。",
        "choice3": "特定（三大）疾病保障定期保険では、がん、急性心筋梗塞、脳卒中以外で被保険者が死亡した場合も死亡保険金が支払われる。",
        "choice4": "変額保険（終身型）では、契約時に定めた保険金額（基本保険金額）が保証されており、運用実績にかかわらず、死亡保険金の額は基本保険金額を下回らない。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 13,
        "shikenId": 1,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "総合福祉団体定期保険および団体定期保険（Bグループ保険）の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。[2024.05]",
        "choice1": "総合福祉団体定期保険は、企業（団体）を契約者、従業員等を被保険者とする1年更新の定期保険であり、その契約の締結に際しては、被保険者になることについての加入予定者の同意が必要である。",
        "choice2": "総合福祉団体定期保険のヒューマン・ヴァリュー特約は、被保険者の死亡等による企業（団体）の経済的損失に備えるものであり、その特約死亡保険金等の受取人は企業（団体）となる。",
        "choice3": "団体定期保険（Bグループ保険）は、従業員等が任意に加入する1年更新の定期保険であり、原則として、従業員等が保険料を負担する。",
        "choice4": "団体定期保険（Bグループ保険）の加入に際しては、保険約款に基づく告知および医師の診査が必要である。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "総合福祉団体定期保険および団体定期保険（Bグループ保険）",
        "important": ""
    },
    {
        "id": 14,
        "shikenId": 1,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人年金保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。[2023.09]",
        "choice1": "確定年金では、年金受取開始日前に被保険者が死亡した場合、死亡給付金受取人が契約時に定められた年金受取総額と同額の死亡給付金を受け取ることができる。",
        "choice2": "10年保証期間付終身年金では、被保険者の性別以外の契約条件が同一である場合、保険料は女性の方が男性よりも高くなる。",
        "choice3": "変額個人年金保険では、特別勘定における運用実績によって、将来受け取る年金額や解約返戻金額が変動する。",
        "choice4": "外貨建て個人年金保険では、円換算支払特約を付加することで、年金や解約返戻金、死亡給付金を円貨で受け取ることができる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "個人年金保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 15,
        "shikenId": 1,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "契約者（＝保険料負担者）を法人とする生命保険に係る保険料等の経理処理に関する次の記述のうち、最も不適切なものはどれか。なお、いずれの保険契約も保険料は年払いかつ全期払いで、2023年10月に締結したものとする。[2024.01]",
        "choice1": "被保険者が役員、死亡保険金受取人が法人である終身保険の支払保険料は、その全額を資産に計上する。",
        "choice2": "被保険者が役員・従業員全員、死亡保険金受取人が被保険者の遺族、満期保険金受取人が法人である養老保険の支払保険料は、その全額を損金の額に算入することができる。",
        "choice3": "被保険者が役員・従業員全員、給付金受取人が法人である医療保険について、法人が受け取った入院給付金および手術給付金は、その全額を益金の額に算入する。",
        "choice4": "被保険者が役員、死亡保険金受取人が法人で、最高解約返戻率が80％である定期保険（保険期間30年）の支払保険料は、保険期間の前半4割相当期間においては、その60％相当額を資産に計上し、残額を損金の額に算入することができる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "法人の生命保険の経理処理",
        "important": ""
    },
    {
        "id": 16,
        "shikenId": 1,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "任意加入の自動車保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。なお、記載のない事項については考慮しないものとする。[2023.01]",
        "choice1": "被保険自動車を運転中に飛び石により窓ガラスにひびが入った場合、一般車両保険の補償の対象となる。",
        "choice2": "被保険自動車を運転中に、通行人が連れていたペットに誤って衝突して死亡させ、法律上の損害賠償責任を負った場合、対物賠償保険の補償の対象となる。",
        "choice3": "被保険自動車を運転中に衝突事故を起こして被保険者がケガをした場合、被保険者の過失割合にかかわらず、人身傷害（補償）保険の補償の対象となる。",
        "choice4": "被保険自動車を運転中に衝突事故を起こして被保険者の配偶者がケガをした場合、対人賠償保険の補償の対象となる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "自動車保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 17,
        "shikenId": 1,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "地震保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。[2024.05]",
        "choice1": "地震保険は、火災保険の契約時だけでなく、火災保険の保険期間中に付帯することもできる。",
        "choice2": "地震保険の保険料は、保険の対象となる居住用建物の構造と所在地によって異なる。",
        "choice3": "地震保険には、「建築年割引」 「免震建築物割引」 「耐震等級割引」 「耐震診断割引」の保険料割引制度があり、これらは重複して適用を受けることができる。",
        "choice4": "地震保険における損害の程度の区分は、「全損」 「大半損」 「小半損」 「一部損」である。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "地震保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 18,
        "shikenId": 1,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "契約者（＝保険料負担者）を法人とする損害保険契約の経理処理に関する次の記述のうち、最も不適切なものはどれか。[2022.05]",
        "choice1": "火災により倉庫を焼失するリスクに備えて、保険期間5年の火災保険に加入し、5年分の保険料を一括で支払った場合、その事業年度に、支払った保険料の全額を損金の額に算入することができる。",
        "choice2": "業務中の事故によりケガを負うリスクに備えて、すべての役員・従業員を被保険者および保険金受取人とする普通傷害保険に加入した場合、その支払った保険料の全額を損金の額に算入することができる。",
        "choice3": "法人が所有する業務用自動車が交通事故で損壊し、法人が受け取った自動車保険の車両保険の保険金で修理をした場合、当該保険金を益金の額に算入し、当該修理費を損金の額に算入することができる。",
        "choice4": "積立傷害保険が満期を迎え、法人が満期返戻金と契約者配当金を受け取った場合、その全額を益金の額に算入し、資産に計上していた積立保険料の累計額を損金の額に算入することができる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "法人の損害保険契約の経理処理",
        "important": ""
    },
    {
        "id": 19,
        "shikenId": 1,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "第三分野の保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。[2023.09]",
        "choice1": "生命保険会社が取り扱う介護保険は、公的介護保険の加入年齢である40歳から加入可能となり、保険期間は65歳までとされる。",
        "choice2": "医療保険では、人間ドック等の治療を目的としない入院をし、異常が発見されなかった場合、入院給付金は支払われない。",
        "choice3": "先進医療特約で先進医療給付金の支払対象とされている先進医療は、療養を受けた時点において厚生労働大臣によって定められたものである。",
        "choice4": "がん保険では、被保険者ががんで入院したことにより受け取る入院給付金について、1回の入院での支払日数に制限はない。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "第三分野の保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 20,
        "shikenId": 1,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "損害保険を活用した家庭のリスク管理に関する次の記述のうち、最も不適切なものはどれか。なお、契約者（＝保険料負担者）は会社員の個人であるものとする。[2022.09]",
        "choice1": "自動車の運転中に誤って単独事故を起こして車両が破損するリスクに備えて、自動車保険の一般条件の車両保険を契約した。",
        "choice2": "海岸近くに自宅を新築したので、地震による津波で自宅が損壊するリスクに備えて、火災保険に地震保険を付帯して契約した。",
        "choice3": "同居の子が原動機付自転車で通学中に、他人に接触してケガをさせて法律上の損害賠償責任を負うリスクに備えて、火災保険加入時に個人賠償責任補償特約を付帯した。",
        "choice4": "所定の病気やケガにより会社の業務にまったく従事することができなくなるリスクに備えて、所得補償保険を契約した。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "損害保険を活用した家庭のリスク管理",
        "important": ""
    },
    {
        "id": 21,
        "shikenId": 1,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "全国企業短期経済観測調査（日銀短観）に関する次の記述のうち、最も適切なものはどれか。[2022.05]",
        "choice1": "日銀短観は、統計法に基づいて行われる調査であり、全国の企業動向を的確に把握し、政府の財政政策の適切な運営に資することを目的としている。",
        "choice2": "日銀短観の調査は年4回実施され、その結果は、3月、6月、9月、12月に公表される。",
        "choice3": "日銀短観の調査対象企業は、全国の資本金1,000万円以上の民間企業（金融機関等を除く）の中から抽出され、各種計数が業種別および企業規模別に公表される。",
        "choice4": "日銀短観で公表される「業況判断DI」は、回答時点の業況とその3カ月後の業況予測について、「良い」と回答した企業の社数構成比から「悪い」と回答した企業の社数構成比を差し引いて算出される。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "全国企業短期経済観測調査（日銀短観）",
        "important": ""
    },
    {
        "id": 22,
        "shikenId": 1,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "銀行等の金融機関で取り扱う預貯金の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。[2024.01]",
        "choice1": "決済用預金は、「無利息」 「要求払い」 「決済サービスを提供できること」という3つの条件を満たした預金である。",
        "choice2": "当座預金は、株式の配当金の自動受取口座として利用することができる。",
        "choice3": "スーパー定期預金は、預入期間が3年以上の場合、単利型と半年複利型があるが、半年複利型を利用することができるのは法人に限られる。",
        "choice4": "大口定期預金は、最低預入金額が1,000万円に設定された固定金利型の定期預金である。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "預貯金の一般的な商品性",
        "important": ""
    },
    {
        "id": 23,
        "shikenId": 1,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "株式投資信託の一般的な運用手法等に関する次の記述のうち、最も適切なものはどれか。[2024.05]",
        "choice1": "マクロ的な環境要因等を基に国別組入比率や業種別組入比率などを決定し、その比率に応じて、個別銘柄を組み入れてポートフォリオを構築する手法を、ボトムアップ・アプローチという。",
        "choice2": "割高な銘柄の買建てと割安な銘柄の売建てをそれぞれ同程度の金額で行い、市場全体の変動に左右されない収益の確保を目指す手法を、マーケット・ニュートラル運用という。",
        "choice3": "企業の将来の売上高や利益の成長性が市場平均よりも高いと見込まれる銘柄を組み入れて運用するグロース運用は、PERやPBRが低い銘柄中心のポートフォリオとなる傾向がある。",
        "choice4": "「ブル型」 「ベア型」と呼ばれる特殊型の投資信託のうち、「ベア型」は、ベンチマークが下落すると基準価額が上昇するように設計されている。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託の分類方法",
        "important": ""
    },
    {
        "id": 24,
        "shikenId": 1,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "上場投資信託（ETF）の一般的な特徴に関する次の記述のうち、最も適切なものはどれか。[2022.05]",
        "choice1": "ETFは、非上場の投資信託と異なり、運用管理費用（信託報酬）は発生しない。",
        "choice2": "ETFは、支払われる分配金が自動で再投資されるため、投資の複利効果を得ることができる。",
        "choice3": "ETFには、NISAの「つみたて投資枠」の対象となっているものがある。",
        "choice4": "ETFには、株価指数に連動するものはあるが、REIT指数や商品指数に連動するものはない。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "上場投資信託（ETF）の一般的な特徴",
        "important": ""
    },
    {
        "id": 25,
        "shikenId": 1,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "債券投資のリスクに関する次の記述のうち、最も不適切なものはどれか。[2024.05]",
        "choice1": "債券の発行体の所在する国や地域における政治・経済状況の変化等により、債券価格が大きく変動したり、債券の元本や利子の支払不能等が生じたりするリスクを、一般に、カントリーリスクという。",
        "choice2": "債券の価格変動リスクは、表面利率などの他の条件が同一であれば、一般に、残存期間が長いほど高くなる。",
        "choice3": "債券の信用格付けにおいて、B（シングルビー）格相当以上の格付けが付された債券は、一般に、投資適格債と呼ばれ、信用リスクが低い債券とされる。",
        "choice4": "外貨建て債券の為替変動リスクを回避または軽減する方法の一つとして、当該債券の購入時に、その償還日にあわせて為替予約を行う為替ヘッジが考えられる。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "債券投資のリスク",
        "important": ""
    },
    {
        "id": 26,
        "shikenId": 1,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "下記＜X社のデータ＞に基づき算出される投資指標に関する次の記述のうち、最も不適切なものはどれか。[2023.05]<br><img src='/img/1_26.jpg'/>",
        "choice1": "ROEは、3.75％である。",
        "choice2": "PERは、18倍である。",
        "choice3": "PBRは、0.54倍である。",
        "choice4": "配当性向は、40％である。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の投資指標",
        "important": ""
    },
    {
        "id": 27,
        "shikenId": 1,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "金融派生商品に関する次の記述のうち、最も適切なものはどれか。[2023.09]",
        "choice1": "クーポンスワップは、異なる通貨間で将来の金利および元本を交換する通貨スワップである。",
        "choice2": "先物取引を利用したヘッジ取引には、将来の価格上昇リスク等を回避または軽減する売りヘッジと、将来の価格下落リスク等を回避または軽減する買いヘッジがある。",
        "choice3": "オプション取引において、コール・オプションの買い手は「権利行使価格で買う権利」を放棄することができるが、プット・オプションの買い手は「権利行使価格で売る権利」を放棄することができない。",
        "choice4": "オプション取引において、コール・オプションの売り手の最大利益とプット・オプションの売り手の最大利益は、いずれもプレミアム（オプション料）の額となる。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "金融派生商品",
        "important": ""
    },
    {
        "id": 28,
        "shikenId": 1,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "ポートフォリオ理論に関する次の記述のうち、最も不適切なものはどれか。[2022.05]",
        "choice1": "ポートフォリオのリスクとは、一般に、組成されたポートフォリオの損失額の大きさを示すのではなく、そのポートフォリオの期待収益率と実際の収益率の乖離の度合いをいう。",
        "choice2": "異なる2資産からなるポートフォリオにおいて、2資産間の相関係数がゼロである場合、ポートフォリオを組成することによる分散投資の効果（リスクの低減効果）は生じない。",
        "choice3": "ポートフォリオの期待収益率は、組み入れた各資産の期待収益率を組入比率で加重平均した値となる。",
        "choice4": "ポートフォリオのリスクは、組み入れた各資産のリスクを組入比率で加重平均した値以下となる。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "ポートフォリオ理論",
        "important": ""
    },
    {
        "id": 29,
        "shikenId": 1,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "上場株式等の譲渡および配当等（一定の大口株主等が受けるものを除く）に係る所得税の課税等に関する次の記述のうち、最も適切なものはどれか。なお、本問においては、特定口座のうち、源泉徴収がされない口座を簡易申告口座といい、源泉徴収がされる口座を源泉徴収選択口座という。[2023.09]",
        "choice1": "上場株式等の配当等について、総合課税を選択して確定申告をした場合、上場株式等に係る譲渡損失の金額と損益通算することができる。",
        "choice2": "上場株式等に係る配当所得等の金額と損益通算してもなお控除しきれない上場株式等に係る譲渡損失の金額は、確定申告をすることにより、翌年以後3年間にわたって繰り越すことができる。",
        "choice3": "簡易申告口座では、源泉徴収選択口座と異なり、その年中における口座内の取引内容が記載された「特定口座年間取引報告書」が作成されないため、投資家自身でその年中の上場株式等に係る譲渡損益および配当等の金額を計算する必要がある。",
        "choice4": "年末調整の対象となる給与所得者が、医療費控除の適用を受けるために確定申告をする場合、源泉徴収選択口座における上場株式等に係る譲渡所得等および配当所得等についても申告しなければならない。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "上場株式等の譲渡および配当等に係る所得税の課税等",
        "important": ""
    },
    {
        "id": 30,
        "shikenId": 1,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "わが国における個人による金融商品取引に係るセーフティネットに関する次の記述のうち、最も適切なものはどれか。[2023.01]",
        "choice1": "確定拠出年金の加入者が運用の方法として選択した定期預金は、加入者の預金として、預金保険制度による保護の対象となる。",
        "choice2": "日本国内で事業を行う生命保険会社が破綻した場合、生命保険契約者保護機構による補償の対象となる保険契約については、高予定利率契約を除き、原則として、破綻時点の責任準備金等の80％まで補償される。",
        "choice3": "証券会社が破綻し、分別管理が適切に行われていなかったために、一般顧客の資産の一部または全部が返還されない事態が生じた場合、日本投資者保護基金により、補償対象債権に係る顧客資産について一般顧客1人当たり2,000万円を上限として補償される。",
        "choice4": "銀行で購入した投資信託は、日本投資者保護基金による保護の対象となる。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "セーフティネット",
        "important": ""
    },
    {
        "id": 31,
        "shikenId": 1,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税の基本的な仕組みに関する次の記述のうち、最も適切なものはどれか。[2024.01]",
        "choice1": "所得税では、納税者が申告した所得金額に基づき、納付すべき税額を税務署長が決定する賦課課税方式が採用されている。",
        "choice2": "所得税の課税対象は国内において生じた所得のみであり、国外において生じた所得が課税対象となることはない。",
        "choice3": "所得税における居住者とは、国内に住所を有し、または現在まで引き続いて1年以上居所を有する個人をいう。",
        "choice4": "所得税額の計算において課税総所得金額に乗じる税率には、課税総所得金額が大きくなるにつれて段階的に税率が高くなる超過累進税率が採用されており、その最高税率は30％である。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の基本的な仕組み",
        "important": ""
    },
    {
        "id": 32,
        "shikenId": 1,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税における各種所得に関する次の記述のうち、最も不適切なものはどれか。[2022.09]",
        "choice1": "不動産所得の金額は、原則として、「不動産所得に係る総収入金額－必要経費」の算式により計算される。",
        "choice2": "賃貸の用に供している土地の所有者が、当該土地を取得した際に支出した仲介手数料は、当該土地の取得価額に算入されるため、その支払った年分の不動産所得の金額の計算上、必要経費に算入することはできない。",
        "choice3": "個人による不動産の貸付けが事業的規模である場合、その賃貸収入による所得は、事業所得に該当する。",
        "choice4": "借家人が賃貸借の目的とされている居宅の立退きに際して受け取る立退き料（借家権の消滅の対価の額に相当する部分の金額を除く）は、原則として一時所得に該当する。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税における各種所得",
        "important": ""
    },
    {
        "id": 33,
        "shikenId": 1,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税の各種所得の金額の計算上生じた次の損失の金額のうち、他の所得の金額と損益通算できないものはどれか。[2023.01]",
        "choice1": "不動産所得の金額の計算上生じた損失の金額のうち、不動産所得を生ずべき建物の取得に要した負債の利子に相当する部分の金額",
        "choice2": "生活の用に供していた自家用車を売却したことによる譲渡所得の金額の計算上生じた損失の金額",
        "choice3": "コンサルティング事業を行ったことによる事業所得の金額の計算上生じた損失の金額",
        "choice4": "取得してから5年が経過した山林を伐採して譲渡したことによる山林所得の金額の計算上生じた損失の金額",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "損益通算できないもの",
        "important": ""
    },
    {
        "id": 34,
        "shikenId": 1,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "所得税における所得控除に関する次の記述のうち、最も適切なものはどれか。[2023.05]",
        "choice1": "納税者が医師の診療に係る医療費を支払った場合、その全額を医療費控除として総所得金額等から控除することができる。",
        "choice2": "納税者が特定一般用医薬品等（スイッチOTC医薬品等）の購入費を支払った場合、その全額を医療費控除として総所得金額等から控除することができる。",
        "choice3": "納税者が確定拠出年金の個人型年金の掛金を支払った場合、その全額を社会保険料控除として総所得金額等から控除することができる。",
        "choice4": "納税者が国民年金基金の掛金を支払った場合、その全額を社会保険料控除として総所得金額等から控除することができる。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得控除",
        "important": ""
    },
    {
        "id": 35,
        "shikenId": 1,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "所得税の申告と納付等に関する次の記述のうち、最も不適切なものはどれか。[2022.09]",
        "choice1": "給与所得者が、医療費控除の適用を受けることにより、給与から源泉徴収された税金の還付を受けようとする場合、納税地の所轄税務署長に確定申告書を提出する必要がある。",
        "choice2": "年間の給与収入の金額が2,000万円を超える給与所得者は、年末調整の対象とならない。",
        "choice3": "確定申告書を提出した納税者が、法定申告期限後に計算の誤りにより所得税を過大に申告していたことに気づいた場合、原則として、法定申告期限から5年以内に限り、更正の請求をすることができる。",
        "choice4": "納税者が、確定申告に係る所得税について延納の適用を受けようとする場合、納期限までに納付すべき所得税額の3分の1相当額以上を納付する必要がある。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の申告と納付等",
        "important": ""
    },
    {
        "id": 36,
        "shikenId": 1,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "法人税の仕組みに関する次の記述のうち、最も適切なものはどれか。[2024.05]",
        "choice1": "法人を設立した場合、設立の日以後1ヵ月以内に、一定の書類を添付した法人設立届出書を納税地の所轄税務署長に提出しなければならない。",
        "choice2": "法人は、法人税の納税地に異動があった場合、原則として、異動届出書を異動前の納税地の所轄税務署長に提出しなければならない。",
        "choice3": "法人税の確定申告書は、原則として、各事業年度終了の日の翌日から1ヵ月以内に、納税地の所轄税務署長に提出しなければならない。",
        "choice4": "期末資本金の額等が1億円以下の一定の中小法人に対する法人税の税率は、所得金額のうち年1,000万円以下の部分について軽減税率が適用される。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "法人税の仕組み",
        "important": ""
    },
    {
        "id": 37,
        "shikenId": 1,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "法人税の損金に関する次の記述のうち、最も不適切なものはどれか。[2023.09]",
        "choice1": "法人が従業員の業務遂行中の交通違反に係る反則金を負担した場合、その負担金は、損金の額に算入することができる。",
        "choice2": "法人が減価償却資産として損金経理した金額のうち、償却限度額に達するまでの金額は、その全額を損金の額に算入することができる。",
        "choice3": "損金の額に算入される租税公課のうち、事業税については、原則として、その事業税に係る納税申告書を提出した日の属する事業年度の損金の額に算入することができる。",
        "choice4": "法人が国または地方公共団体に対して支払った寄附金は、原則として、その全額を損金の額に算入することができる。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "法人税の損金",
        "important": ""
    },
    {
        "id": 38,
        "shikenId": 1,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "消費税に関する次の記述のうち、最も不適切なものはどれか。[2023.05]",
        "choice1": "土地の譲渡は、非課税取引に該当する。",
        "choice2": "新たに設立した普通法人のうち、事業年度開始の日における資本金の額等が1,000万円以上である法人は、基準期間がない課税期間において消費税の課税事業者となる。",
        "choice3": "基準期間における課税売上高が1億円である課税事業者は、所定の手続きにより、簡易課税制度の適用を受けることができる。",
        "choice4": "課税事業者である個人事業者は、原則として、消費税の確定申告書をその年の翌年3月31日までに納税地の所轄税務署長に提出しなければならない。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "消費税",
        "important": ""
    },
    {
        "id": 39,
        "shikenId": 1,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "会社と役員間の取引に係る所得税・法人税に関する次の記述のうち、最も不適切なものはどれか。[2022.05]",
        "choice1": "役員が所有する土地を会社に譲渡した場合、その譲渡価額が適正な時価の2分の1未満であるときは、適正な時価により譲渡所得の金額が計算される。",
        "choice2": "役員が会社に無利息で金銭の貸付けを行った場合、原則として、通常収受すべき利息に相当する金額が、その役員の雑所得の収入金額に算入される。",
        "choice3": "会社が所有する建物を適正な時価よりも高い価額で役員に譲渡した場合、その会社の所得の金額の計算上、適正な時価と譲渡対価の差額は、益金の額に算入される。",
        "choice4": "会社が役員に無利息で金銭の貸付けを行った場合、原則として、その会社の所得の金額の計算上、適正な利率により計算した利息相当額が益金の額に算入される。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "会社と役員間の取引",
        "important": ""
    },
    {
        "id": 40,
        "shikenId": 1,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "貸借対照表および損益計算書の一般的な特徴に関する次の記述のうち、最も不適切なものはどれか。[2024.01]",
        "choice1": "貸借対照表の無形固定資産は、物理的な形態を持たない特許権や商標権等の資産の金額を表示している。",
        "choice2": "貸借対照表の固定負債は、返済期限が決算日の翌日から起算して1年以内に到来しない借入金等の負債の金額を表示している。",
        "choice3": "損益計算書の営業利益は、売上総利益金額から販売費及び一般管理費の合計額を控除した金額を表示している。",
        "choice4": "損益計算書の税引前当期純利益は、経常利益または経常損失の金額に営業外収益・営業外費用を加算・減算した金額を表示している。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "貸借対照表および損益計算書の一般的な特徴",
        "important": ""
    },
    {
        "id": 41,
        "shikenId": 1,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "不動産の登記や調査に関する次の記述のうち、最も不適切なものはどれか。[2023.09]",
        "choice1": "抵当権の登記の登記事項は、権利部乙区に記録される。",
        "choice2": "区分建物を除く建物に係る登記記録において、床面積は、壁その他の区画の中心線で囲まれた部分の水平投影面積（壁芯面積）により記録される。",
        "choice3": "新築した建物の所有権を取得した者は、その所有権の取得の日から1ヵ月以内に、所有権保存登記を申請しなければならない。",
        "choice4": "登記情報提供サービスでは、登記所が保有する登記情報を、インターネットを使用してパソコン等で確認することができるが、取得した登記情報に係る電子データには登記官の認証文は付されない。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の登記や調査に関する次の記述",
        "important": ""
    },
    {
        "id": 42,
        "shikenId": 1,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "民法および借地借家法に関する次の記述のうち、最も不適切なものはどれか。なお、本問においては、借地借家法第38条における定期建物賃貸借契約を定期借家契約といい、それ以外の建物賃貸借契約を普通借家契約という。また、記載のない特約については考慮しないものとする。[2024.01]",
        "choice1": "賃借人は、建物の引渡しを受けた後の通常の使用および収益によって生じた建物の損耗ならびに経年変化については、賃貸借が終了したときに原状に復する義務を負わない。",
        "choice2": "普通借家契約において、賃借人が賃貸人の同意を得て建物に付加した造作について、賃貸借終了時、賃借人が賃貸人に、その買取りを請求しない旨の特約をした場合、その特約は無効である。",
        "choice3": "定期借家契約を締結するときは、賃貸人は、あらかじめ、賃借人に対し、契約の更新がなく、期間満了により賃貸借が終了することについて、その旨を記載した書面を交付し、または、賃借人の承諾を得て当該書面に記載すべき事項を電磁的方法により提供して、説明しなければならない。",
        "choice4": "定期借家契約において、経済事情の変動があっても賃貸借期間中は賃料を増減額しないこととする特約をした場合、その特約は有効である。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "民法および借地借家法（借家）",
        "important": ""
    },
    {
        "id": 43,
        "shikenId": 1,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "宅地建物取引業法に関する次の記述のうち、最も不適切なものはどれか。なお、買主は宅地建物取引業者ではないものとする。[2023.05]",
        "choice1": "宅地建物取引業者が建物の貸借の媒介を行う場合、貸主と借主の双方から受け取ることができる報酬の合計額は、当該建物の借賃（消費税等相当額を除く）の2ヵ月分に相当する額に消費税等相当額を加算した額が上限となる。",
        "choice2": "宅地建物取引業者は、自ら売主となる宅地の売買契約の締結に際して、代金の額の10分の2を超える額の手付を受領することができない。",
        "choice3": "宅地建物取引業者が、自ら売主となる宅地の売買契約の締結に際して手付を受領したときは、その手付がいかなる性質のものであっても、買主が契約の履行に着手する前であれば、当該宅地建物取引業者はその倍額を現実に提供して、契約の解除をすることができる。",
        "choice4": "専任媒介契約の有効期間は、3ヵ月を超えることができず、これより長い期間を定めたときは、その期間は3ヵ月とされる。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "宅地建物取引業法",
        "important": ""
    },
    {
        "id": 44,
        "shikenId": 1,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "都市計画法に関する次の記述のうち、最も適切なものはどれか。[2023.05]",
        "choice1": "すべての都市計画区域において、都市計画に市街化区域と市街化調整区域の区分（区域区分）を定めなければならない。",
        "choice2": "都市計画区域のうち、用途地域が定められている区域については、防火地域または準防火地域のいずれかを定めなければならない。",
        "choice3": "市街化調整区域内において、農業を営む者の居住の用に供する建築物の建築の用に供する目的で行う開発行為は、開発許可を受ける必要はない。",
        "choice4": "土地区画整理事業の施行として行う開発行為は、開発許可を受けなければならない。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "都市計画法",
        "important": ""
    },
    {
        "id": 45,
        "shikenId": 1,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "都市計画区域および準都市計画区域内における建築基準法の規定に関する次の記述のうち、最も不適切なものはどれか。[2024.01]",
        "choice1": "建築基準法第42条第2項により道路境界線とみなされる線と道路との間の敷地部分（セットバック部分）は、建蔽率を算定する際の敷地面積に算入することができない。",
        "choice2": "建築物の敷地が2つの異なる用途地域にわたる場合、その全部について、敷地の過半の属する用途地域の建築物の用途に関する規定が適用される。",
        "choice3": "防火地域内にある耐火建築物は、いずれの用途地域内にある場合であっても、建蔽率の制限に関する規定の適用を受けない。",
        "choice4": "商業地域内の建築物には、北側斜線制限（北側高さ制限）は適用されない。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "建築基準法",
        "important": ""
    },
    {
        "id": 46,
        "shikenId": 1,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "建物の区分所有等に関する法律に関する次の記述のうち、最も不適切なものはどれか。[2022.09]",
        "choice1": "区分所有建物ならびにその敷地および附属施設の管理を行うための区分所有者の団体（管理組合）は、区分所有者全員で構成される。",
        "choice2": "区分所有建物のうち、構造上の独立性と利用上の独立性を備えた建物の部分は、区分所有権の目的となる専有部分であり、規約によって共用部分とすることはできない。",
        "choice3": "共用部分に対する区分所有者の共有持分は、規約に別段の定めがない限り、各共有者が有する専有部分の床面積の割合による。",
        "choice4": "規約を変更するためには、区分所有者および議決権の各4分の3以上の多数による集会の決議が必要となり、この変更が一部の区分所有者の権利に特別の影響を及ぼすべきときは、当該区分所有者の承諾を得なければならない。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "区分所有法",
        "important": ""
    },
    {
        "id": 47,
        "shikenId": 1,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "不動産に係る固定資産税および都市計画税に関する次の記述のうち、最も適切なものはどれか。[2022.09]",
        "choice1": "年の中途に固定資産税の課税対象となる土地または家屋が譲渡された場合、その譲受人は、原則として、その年度内の所有期間に応じた当年度分の固定資産税を納付しなければならない。",
        "choice2": "住宅用地に係る固定資産税の課税標準については、住宅1戸当たり400㎡以下の部分について課税標準となるべき価格の6分の1相当額とする特例がある。",
        "choice3": "都市計画税の税率は各地方自治体の条例で定められるが、100分の0.3を超えることはできない。",
        "choice4": "都市計画税は、都市計画区域のうち、原則として、市街化調整区域および非線引きの区域内に所在する土地および家屋の所有者に対して課される。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産に係る固定資産税および都市計画税",
        "important": ""
    },
    {
        "id": 48,
        "shikenId": 1,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "居住用財産を譲渡した場合の3,000万円の特別控除（以下「3,000万円特別控除」という）および居住用財産を譲渡した場合の長期譲渡所得の課税の特例（以下「軽減税率の特例」という）に関する次の記述のうち、最も不適切なものはどれか。なお、記載されたもの以外の要件はすべて満たしているものとする。[2023.01]",
        "choice1": "3,000万円特別控除は、居住用財産を配偶者に譲渡した場合には適用を受けることができない。",
        "choice2": "3,000万円特別控除は、譲渡した居住用財産の所有期間が、譲渡した日の属する年の1月1日において10年を超えていなければ、適用を受けることができない。",
        "choice3": "軽減税率の特例では、課税長期譲渡所得金額のうち6,000万円以下の部分の金額について、所得税（復興特別所得税を含む）10.21％、住民税4％の軽減税率が適用される。",
        "choice4": "3,000万円特別控除と軽減税率の特例は、重複して適用を受けることができる。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "3,000万円特別控除、軽減税率の特例",
        "important": ""
    },
    {
        "id": 49,
        "shikenId": 1,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "不動産の有効活用の手法の一般的な特徴に関する次の記述のうち、最も不適切なものはどれか。[2022.05]",
        "choice1": "事業受託方式は、土地の有効活用の企画、建設会社の選定や当該土地上に建設された建物の管理・運営等をデベロッパーに任せ、建設資金の調達や返済は土地所有者が行う方式である。",
        "choice2": "等価交換方式は、土地所有者とデベロッパーの共同事業として、土地所有者が土地を出資し、デベロッパーが建設資金を出資して建物を建て、それぞれの出資比率に応じて土地や建物を取得する方式である。",
        "choice3": "建設協力金方式では、土地所有者が土地の上に建物を建てる際に、事業者が土地所有者に資金を提供するため、当該建物の所有名義は事業者となる。",
        "choice4": "定期借地権方式では、土地所有者は土地を一定期間貸し付けることにより地代収入を得ることができ、当該土地上に建設される建物の建設資金を負担する必要がない。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の有効活用の手法",
        "important": ""
    },
    {
        "id": 50,
        "shikenId": 1,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "不動産の投資判断手法等に関する次の記述のうち、最も適切なものはどれか。[2024.05]",
        "choice1": "NOI利回り（純利回り）は、対象不動産から得られる年間の純収益を総投資額で除して算出される利回りであり、不動産の収益性を測る指標である。",
        "choice2": "NPV法（正味現在価値法）による投資判断においては、対象不動産から得られる収益の現在価値の合計額が投資額を下回っている場合、その投資は有利であると判定することができる。",
        "choice3": "DSCR（借入金償還余裕率）は、対象不動産から得られる収益による借入金の返済余裕度を評価する指標であり、対象不動産に係る当該指標の数値が1.0を下回っている場合は、対象不動産から得られる収益だけで借入金を返済することができる。",
        "choice4": "DCF法は、対象不動産の一期間の純収益を還元利回りで還元して対象不動産の価格を求める手法である。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の投資判断手法等",
        "important": ""
    },
    {
        "id": 51,
        "shikenId": 1,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "親族等に係る民法の規定に関する次の記述のうち、最も適切なものはどれか。[2024.05]",
        "choice1": "親族の範囲は、3親等内の血族、配偶者、6親等内の姻族である。",
        "choice2": "兄弟姉妹の子（甥や姪）は、3親等の血族である。",
        "choice3": "配偶者の父母は、2親等の姻族である。",
        "choice4": "子の配偶者は、2親等の姻族である。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "親族等に係る民法の規定",
        "important": ""
    },
    {
        "id": 52,
        "shikenId": 1,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "贈与税の申告と納付に関する次の記述のうち、最も適切なものはどれか。[2023.09]",
        "choice1": "贈与税の申告書は、原則として、贈与を受けた年の翌年2月1日から3月15日までの間に、受贈者の納税地の所轄税務署長に提出しなければならない。",
        "choice2": "国税電子申告・納税システム（e-Tax）は、贈与税の申告には対応していない。",
        "choice3": "贈与税を納期限までに納付することが困難である場合、その納付を困難とする金額を限度として延納または物納を申請することができる。",
        "choice4": "贈与税の納付について認められる延納期間は、最長10年である。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の申告と納付",
        "important": ""
    },
    {
        "id": 53,
        "shikenId": 1,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "民法上の相続人等に関する次の記述のうち、最も適切なものはどれか。なお、記載のない事項については考慮しないものとする。[2024.01]",
        "choice1": "離婚した元配偶者との間に出生した被相続人の子が当該元配偶者の親権に服している場合、その子は相続人とならない。",
        "choice2": "特別養子縁組による養子は、実方の父母および養親の相続人となる。",
        "choice3": "被相続人の子が廃除により相続権を失った場合、その者に被相続人の直系卑属である子がいるときは、その子（被相続人の孫）は代襲相続人となる。",
        "choice4": "被相続人と婚姻の届出をしていないが、被相続人といわゆる内縁関係にあった者は、被相続人の配偶者とみなされて相続人となる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "民法上の相続人等",
        "important": ""
    },
    {
        "id": 54,
        "shikenId": 1,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "遺産分割に関する次の記述のうち、最も適切なものはどれか。[2023.01]",
        "choice1": "適法に成立した遺産分割協議については、共同相続人全員の合意があったとしても、解除することは認められない。",
        "choice2": "代償分割は、現物分割を困難とする事由がある場合に、共同相続人が家庭裁判所に申し立て、その審判を受けることにより認められる。",
        "choice3": "相続財産である不動産を、共同相続人間で遺産分割するために譲渡して換価した場合、その譲渡による所得は、所得税において非課税所得とされている。",
        "choice4": "被相続人は、遺言によって、相続開始の時から5年を超えない期間を定めて、遺産の分割を禁ずることができる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺産分割",
        "important": ""
    },
    {
        "id": 55,
        "shikenId": 1,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "相続税の非課税財産に関する次の記述のうち、最も適切なものはどれか。[2024.01]",
        "choice1": "被相続人の死亡により、相続人が被相続人に支給されるべきであった退職手当金の支給を受けた場合、当該退職手当金の支給が被相続人の死亡後5年以内に確定したものであれば、相続人は、当該退職手当金について死亡退職金の非課税金額の規定の適用を受けることができる。",
        "choice2": "死亡退職金の非課税金額の規定による非課税限度額は、被相続人の死亡が業務上の死亡である場合、被相続人の死亡時における賞与以外の普通給与の3年分に相当する金額である。",
        "choice3": "契約者（＝保険料負担者）および被保険者を被相続人とする生命保険契約に基づき、相続の放棄をした者が受け取った死亡保険金については、死亡保険金の非課税金額の規定は適用されない。",
        "choice4": "死亡保険金の非課税金額の規定による非課税限度額の計算上の相続人の数には、相続の放棄をした者は含まれない。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続税の非課税財産",
        "important": ""
    },
    {
        "id": 56,
        "shikenId": 1,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "民法上の遺言に関する次の記述のうち、最も適切なものはどれか。[2023.09]",
        "choice1": "相続人が自筆証書遺言を発見し、家庭裁判所の検認を受ける前に開封した場合、その遺言は無効となる。",
        "choice2": "遺言者が自筆証書遺言に添付する財産目録をパソコンで作成する場合、当該目録への署名および押印は不要である。",
        "choice3": "公正証書遺言の作成において、遺言者の推定相続人とその配偶者は証人として立ち会うことができない。",
        "choice4": "公正証書遺言は、自筆証書遺言によって撤回することはできず、公正証書遺言によってのみ撤回することができる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺言",
        "important": ""
    },
    {
        "id": 57,
        "shikenId": 1,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "相続税の計算に関する次の記述のうち、最も適切なものはどれか。なお、本問において、相続の放棄をした者はいないものとする。[2023.01]",
        "choice1": "遺産に係る基礎控除額の計算上、法定相続人の数に含めることができる養子（実子とみなされる者を除く）の数は、実子がいる場合、2人に制限される。",
        "choice2": "相続人となるべき被相続人の子がすでに死亡しているため、その死亡した子を代襲して相続人となった被相続人の孫は、相続税額の2割加算の対象者となる。",
        "choice3": "相続人が被相続人の配偶者のみである場合、「配偶者に対する相続税額の軽減」の適用を受けた配偶者については、相続により取得した遺産額の多寡にかかわらず、納付すべき相続税額が生じない。",
        "choice4": "「配偶者に対する相続税額の軽減」の適用を受けることができる配偶者は、被相続人と法律上の婚姻の届出をした者に限られず、いわゆる内縁の配偶者も含まれる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続税の計算",
        "important": ""
    },
    {
        "id": 58,
        "shikenId": 1,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "相続税における取引相場のない株式の評価に関する次の記述のうち、最も適切なものはどれか。なお、特定の評価会社の株式には該当しないものとする。[2023.05]",
        "choice1": "類似業種比準方式における比準要素は、1株当たりの配当金額、1株当たりの利益金額および1株当たりの純資産価額（帳簿価額によって計算した金額）である。",
        "choice2": "会社規模が大会社である会社において、中心的な同族株主が取得した株式の価額は、原則として、類似業種比準方式と純資産価額方式の併用方式によって評価する。",
        "choice3": "会社規模が小会社である会社において、中心的な同族株主が取得した株式の価額は、原則として、類似業種比準方式によって評価する。",
        "choice4": "同族株主のいる会社において、同族株主以外の株主が取得した株式の価額は、その会社規模にかかわらず、原則として、純資産価額方式によって評価する。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "取引相場のない株式の評価",
        "important": ""
    },
    {
        "id": 59,
        "shikenId": 1,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "普通住宅地区に所在している下記＜資料＞の宅地の相続税評価額（自用地評価額）として、最も適切なものはどれか。なお、記載のない事項については考慮しないものとする。[2022.05]<br><img src='/img/1_59.jpg'/>",
        "choice1": "85,554千円",
        "choice2": "87,300千円",
        "choice3": "88,200千円",
        "choice4": "90,000千円",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "宅地の相続税評価額",
        "important": ""
    },
    {
        "id": 60,
        "shikenId": 1,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "会社法に関する次の記述のうち、最も不適切なものはどれか[2024.01]",
        "choice1": "すべての株式会社は、取締役会を置かなければならない。",
        "choice2": "株式会社において株主は、その有する株式の引受価額を限度として責任を負う。",
        "choice3": "定時株主総会は、毎事業年度終了後一定の時期に招集しなければならないが、臨時株主総会は、必要がある場合にいつでも招集することができる。",
        "choice4": "取締役は、いつでも、株主総会の決議によって解任することができる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "会社法に関する記述",
        "important": ""
    },
    {
        "id": 61,
        "shikenId": 4,
        "no": 1,
        "answerType": 7,
        "groupId": 1,
        "answer": "1,1,1,2",
        "score": "1,1,1,1",
        "question1": "ファイナンシャル・プランナー（以下「ＦＰ」という）は、ファイナンシャル・プランニング業務を行ううえで関連業法等を順守することが重要である。ＦＰの行為に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。 ",
        "question2": "",
        "choice1": "生命保険募集人・保険仲立人の登録を受けていないＦＰが、顧客が持参したパンフレットの変額個人年金保険について商品説明を行った。",
        "choice2": "弁護士資格を有していないＦＰ（遺言者や公証人と利害関係はない成年者）が、顧客から依頼されて公正証書遺言の証人となり、顧客から適正な報酬を受け取った。 ",
        "choice3": "税理士資格を有していないＦＰが、参加費有料の相続対策セミナーを開催し、仮定の事例に基づく一般的な相続税対策について解説した。 ",
        "choice4": "投資助言・代理業の登録を受けていないＦＰが、顧客の相談を有償で受け、顧客自身が持参した投資信託の運用報告書の内容を確認し、この投資信託の価値等の分析に基づいて、解約するよう助言した。 ",
        "choice5": "2023.01",
        "categoryName": "ライフ",
        "rontenName": "FPと関連業法",
        "important": ""
    },
    {
        "id": 62,
        "shikenId": 4,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "｢金融サービスの提供に関する法律（以下「金融サービス提供法」という）」に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "金融サービス提供法は、金融商品販売業者等が金融商品の販売またはその代理もしくは媒介に際し、顧客に対し説明すべき事項等を定めること等により、顧客の保護を図る法律である。",
        "choice2": "金融サービス提供法は、「金融商品の販売等に関する法律（金融商品販売法）」が改称された法律である。 ",
        "choice3": "投資信託等の売買の仲介を行うＩＦＡ（Independent Financial Advisor＝独立系ファイナンシャル・アドバイザー）は、金融サービス提供法が適用される。",
        "choice4": "投資は投資者自身の判断と責任において行うべきであり、金融サービス提供法では、金融商品販売業者等が重要事項の説明義務を怠ったことで顧客に損害が生じたとしても、金融商品販売業者等は損害賠償責任を負うわけではない。",
        "choice5": "2023.01",
        "categoryName": "ライフ",
        "rontenName": "金融サービス提供法",
        "important": ""
    },
    {
        "id": 63,
        "shikenId": 4,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "大下さんは、保有しているＲＴ投資信託（追加型国内公募株式投資信託）の収益分配金を本年４月に受け取った。ＲＴ投資信託の運用状況が下記＜資料＞のとおりである場合、収益分配後の個別元本として、正しいものはどれか。<br><img src='/img/2_63.png'/>",
        "question2": "",
        "choice1": "１１,５６０円",
        "choice2": "１１,６００円",
        "choice3": "１１,６８０円",
        "choice4": "１１,７２０円",
        "choice5": "2024.05改",
        "categoryName": "金融",
        "rontenName": "投資信託の個別元本",
        "important": ""
    },
    {
        "id": 64,
        "shikenId": 4,
        "no": 4,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "大津さんは、投資信託への投資を検討するに当たり、ＦＰの細井さんから候補である３ファンドの過去３年間の運用パフォーマンスについて説明を受けた。ＦＰの細井さんが下記＜資料＞に基づいて説明した内容の空欄（ア）～（ウ）にあてはまる語句および数値の組み合わせとして、最も適切なものはどれか。<br><img src='/img/2_64.png'/>",
        "question2": "",
        "choice1": "（ア）ＫＸファンド （イ）ＫＹファンド （ウ）１.５０",
        "choice2": "（ア）ＫＹファンド （イ）ＫＺファンド （ウ）０.４５",
        "choice3": "（ア）ＫＺファンド （イ）ＫＸファンド （ウ）０.８０",
        "choice4": "（ア）ＫＺファンド （イ）ＫＹファンド （ウ）１.２５",
        "choice5": "2024.05",
        "categoryName": "金融",
        "rontenName": "シャープレシオ",
        "important": ""
    },
    {
        "id": 65,
        "shikenId": 4,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "下記＜資料＞は、有馬さんが同一の特定口座内で行ったＲＡ株式会社の株式の取引等に係る明細である。有馬さんが２０Ｘ４年３月４日に売却した５,０００株について、譲渡所得の取得費の計算の基礎となる１株当たりの取得価額として、正しいものはどれか。なお、消費税その他記載のない事項については一切考慮しないものとする。<br><img src='/img/2_65.png'/>",
        "question2": "",
        "choice1": "８００円",
        "choice2": "９２０円",
        "choice3": "９６０円",
        "choice4": "１,０４０円",
        "choice5": "2024.05",
        "categoryName": "金融",
        "rontenName": "譲渡所得の計算",
        "important": ""
    },
    {
        "id": 66,
        "shikenId": 4,
        "no": 6,
        "answerType": 7,
        "groupId": "-1",
        "answer": "2,1,2,2",
        "score": "1,1,1,1",
        "question1": "下記＜資料＞は、香川さんが購入を検討している個人向け国債の商品概要の一部である。個人向け国債に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。なお、問題作成の都合上、一部を「＊＊＊」にしてある。 <br><img src='/img/2_66.png'/>",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "金融",
        "rontenName": "個人向け国債",
        "important": ""
    },
    {
        "id": 67,
        "shikenId": 4,
        "no": 7,
        "answerType": 9,
        "groupId": 1,
        "answer": "172.8",
        "score": 2,
        "question1": "建築基準法に従い、下記＜資料＞の甲土地に建物を建てる場合の建築面積の最高限度を計算しなさい。なお、＜資料＞に記載のない条件については一切考慮しないこととする。また、解答に当たっては、解答欄に記載されている単位に従うこと（解答欄に数値を入力すること）。<br><img src='/img/2_67.png'/>",
        "question2": "",
        "choice1": "㎡",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "不動産",
        "rontenName": "建築面積の最高限度",
        "important": ""
    },
    {
        "id": 68,
        "shikenId": 4,
        "no": 8,
        "answerType": 7,
        "groupId": "-1",
        "answer": "1,1,2,2",
        "score": "1,1,1,1",
        "question1": "下記＜資料＞は、大垣一郎さんが所有する土地の登記事項証明書の一部である。この登記事項証明書に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。なお、＜資料＞に記載のない事項は一切考慮しないこととする。<br><img src='/img/2_68.png'/>",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "不動産",
        "rontenName": "登記事項証明書",
        "important": ""
    },
    {
        "id": 69,
        "shikenId": 4,
        "no": 9,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "公的な土地評価に関する下表の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。<br><img src='/img/2_69.png'/>",
        "question2": "",
        "choice1": "（ア）国土交通省 （イ）７０％ （ウ）贈与税",
        "choice2": "（ア）国土交通省 （イ）８０％ （ウ）贈与税",
        "choice3": "（ア）内閣府 （イ）８０％ （ウ）不動産取得税",
        "choice4": "（ア）内閣府  （イ）７０％ （ウ）不動産取得税",
        "choice5": "2022.05",
        "categoryName": "不動産",
        "rontenName": "公的な土地評価",
        "important": ""
    },
    {
        "id": 70,
        "shikenId": 4,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "杉山さんは、９年前に相続により取得し、その後継続して居住している自宅の土地および建物の売却を検討している。売却に係る状況が下記＜資料＞のとおりである場合、所得税における課税長期譲渡所得の金額として、正しいものはどれか。なお、＜資料＞に記載のない事項は一切考慮しないこととする。<br><img src='/img/2_70.png'/>",
        "question2": "",
        "choice1": "３,６００万円",
        "choice2": "３,３６０万円",
        "choice3": "３,２７０万円",
        "choice4": "３,０３０万円",
        "choice5": "2022.05",
        "categoryName": "不動産",
        "rontenName": "課税長期譲渡所得の金額",
        "important": ""
    },
    {
        "id": 71,
        "shikenId": 4,
        "no": 11,
        "answerType": 8,
        "groupId": 1,
        "answer": "3,6,5",
        "score": "1,1,1",
        "question1": "宮本亜紀さん（３７歳）は医療保険への加入を検討しており、下記＜資料１＞＜資料２＞の２つの商品内容を比較している。次の記述の空欄（ア）～（ウ）にあてはまる適切な語句を語群の中から選び、その番号のみを解答欄に入力しなさい。なお、各々の記述はそれぞれ独立した問題であり、相互に影響を与えないものとする。<br><img src='/img/2_71_1.png'/><br><img src='/img/2_71_2.png'/><br><img src='/img/2_71_3.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "リスク",
        "rontenName": "保険提案書 保険金の金額",
        "important": ""
    },
    {
        "id": 72,
        "shikenId": 4,
        "no": 12,
        "answerType": 7,
        "groupId": "-1",
        "answer": "1,2,1,2",
        "score": "1,1,1,1",
        "question1": "広尾吉弘さんが契約している下記＜資料＞の生命保険に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。なお、吉弘さんの家族構成は以下のとおりであり、課税対象となる保険金はいずれも基礎控除額を超えているものとする。<br><img src='/img/2_72.png'/>",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "リスク",
        "rontenName": "生命保険の課税関係",
        "important": ""
    },
    {
        "id": 73,
        "shikenId": 4,
        "no": 13,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "下記＜資料＞は、藤原さんが契約した生命保険の契約の流れを示したものである。この保険契約の責任開始日（保障が開始する日）として、最も適切なものはどれか。なお、責任開始日（期）に関する特約等はない契約であり、保険料は月払いとする。<br><img src='/img/2_73.png'/>",
        "question2": "",
        "choice1": "２０２４年３月 ８日",
        "choice2": "２０２４年３月１７日",
        "choice3": "２０２４年３月２３日",
        "choice4": "２０２４年４月 １日",
        "choice5": "2024.05",
        "categoryName": "リスク",
        "rontenName": "生命保険責任開始日",
        "important": ""
    },
    {
        "id": 74,
        "shikenId": 4,
        "no": 14,
        "answerType": 7,
        "groupId": 1,
        "answer": "2,1,1,2",
        "score": "1,1,1,1",
        "question1": "吉田さん（４６歳）が自身を記名被保険者として契約している下記＜資料＞の自動車保険に関するＦＰの馬場さんが行った次の（ア）～（エ）の説明のうち、適切なものには○、不適切なものには×を解答欄に入力しなさい。なお、＜資料＞に記載のない特約については考慮しないものとする。<br><img src='/img/2_74.png'/>",
        "question2": "",
        "choice1": "「台風による飛来物が衝突して被保険自動車が損害を被った場合、その車両損害は補償の対象になりません。」",
        "choice2": "「吉田さんが被保険自動車を運転中に、同乗していた友人が事故で死傷した場合、人身傷害保険から支払われる保険金とは別に、搭乗者傷害保険からも保険金を受け取ることができます。」",
        "choice3": "「実家を出て一人暮らしをしている吉田さんの長女（未婚、１８歳）が、帰省中に被保険自動車を運転して対物事故を起こした場合、補償の対象になります。」",
        "choice4": "「吉田さんが、所有する自動二輪車（総排気量２５０ｃｃ）を運転中に事故を起こして他人にケガを負わせてしまった場合、ファミリーバイク特約の補償の対象となります。」",
        "choice5": "2024.05",
        "categoryName": "リスク",
        "rontenName": "自動車保険証券",
        "important": ""
    },
    {
        "id": 75,
        "shikenId": 4,
        "no": 15,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "会社員の榎田さんが本年中に支払った医療費等が下記＜資料＞のとおりである場合、榎田さんの本年分の所得税の確定申告における医療費控除の金額として、正しいものはどれか。なお、榎田さんの本年分の所得は、給与所得６１０万円のみであるものとし、榎田さんは妻および母と生計を一にしている。また、セルフメディケーション税制（特定一般用医薬品等購入費を支払った場合の医療費控除の特例）については考慮せず、保険金等により補てんされる金額はないものとする。<br><img src='/img/2_75.png'/>",
        "question2": "",
        "choice1": "４３,０００円",
        "choice2": "４６３,０００円",
        "choice3": "４９３,０００円",
        "choice4": "５５０,０００円",
        "choice5": "2023.09改",
        "categoryName": "タックス",
        "rontenName": "医療費控除の金額",
        "important": ""
    },
    {
        "id": 76,
        "shikenId": 4,
        "no": 16,
        "answerType": 9,
        "groupId": 1,
        "answer": 45,
        "score": 2,
        "question1": "会社員の増田さんの本年分の所得等が下記＜資料＞のとおりである場合、増田さんが本年分の所得税の確定申告を行う際に、給与所得と損益通算できる損失はいくらになるか。なお、▲が付された所得金額は、その所得に損失が発生していることを意味するものとする。また、記載のない事項については一切考慮しないものとし、解答に当たっては、解答欄に記載されている単位に従うこと。<br><img src='/img/2_76.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "タックス",
        "rontenName": "給与所得と損益通算できる損失",
        "important": ""
    },
    {
        "id": 77,
        "shikenId": 4,
        "no": 17,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "広尾さん（６６歳）の本年分の収入等が下記＜資料＞のとおりである場合、広尾さんの本年分の所得税における総所得金額として、正しいものはどれか。なお、記載のない事項については一切考慮しないものとし、総所得金額が最も少なくなるように計算すること。<br><img src='/img/2_77.png'/>",
        "question2": "",
        "choice1": "３３５万円",
        "choice2": "３４５万円",
        "choice3": "３５５万円",
        "choice4": "３９０万円",
        "choice5": "2023.09改",
        "categoryName": "タックス",
        "rontenName": "総所得金額はどれか",
        "important": ""
    },
    {
        "id": 78,
        "shikenId": 4,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "住吉さんは、加入していた下記＜資料＞の養老保険が本年８月に満期を迎えたため、満期保険金を一括で受け取った。住吉さんの本年分の所得税において、総所得金額に算入すべき一時所得の金額として、正しいものはどれか。なお、住吉さんには、この満期保険金以外に一時所得の対象となるものはないものとする。<br><img src='/img/2_78.png'/>",
        "question2": "",
        "choice1": "１０万円",
        "choice2": "２０万円",
        "choice3": "３５万円",
        "choice4": "７０万円",
        "choice5": "2023.09改",
        "categoryName": "タックス",
        "rontenName": "総所得金額に算入すべき一時所得",
        "important": ""
    },
    {
        "id": 79,
        "shikenId": 4,
        "no": 19,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "下記＜資料＞の土地に係る路線価方式による普通借地権の相続税評価額の計算式として、正しいものはどれか。<br><img src='/img/2_79.png'/>",
        "question2": "",
        "choice1": "２００千円×１.００×３００㎡",
        "choice2": "２００千円×１.００×３００㎡ ×７０％",
        "choice3": "２００千円×１.００×３００㎡ ×（１－７０％）",
        "choice4": "２００千円×１.００×３００㎡ ×（１－７０％×３０％×１００％）",
        "choice5": "2023.01",
        "categoryName": "相続",
        "rontenName": "路線価方式による相続税評価額",
        "important": ""
    },
    {
        "id": 80,
        "shikenId": 4,
        "no": 20,
        "answerType": 8,
        "groupId": 1,
        "answer": "1,2,1,4",
        "score": "1,1,1,1",
        "question1": "馬場さんは、ＦＰで税理士でもある藤原さんに、相続税において相続財産から控除できる債務等に関する質問をした。下記の空欄（ア）～（エ）に入る適切な語句を語群の中から選び、その番号のみを解答欄に入力しなさい。なお、同じ番号を何度選んでもよいこととする。<br><img src='/img/2_80.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "相続",
        "rontenName": "債務控除",
        "important": ""
    },
    {
        "id": 81,
        "shikenId": 4,
        "no": 21,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "工藤さん（５９歳）は、本年１２月に夫から居住用不動産（財産評価額２,７５０万円）の贈与を受けた。工藤さんが贈与税の配偶者控除の適用を受けた場合の本年分の贈与税額として、正しいものはどれか。なお、本年においては、このほかに工藤さんが受けた贈与はないものとする。また、納付すべき贈与税額が最も少なくなるように計算すること。<br><img src='/img/2_81.png'/>",
        "question2": "",
        "choice1": "１４万円",
        "choice2": "１０２万円",
        "choice3": "１３１万円",
        "choice4": "１７５万円",
        "choice5": "2023.01改",
        "categoryName": "相続",
        "rontenName": "贈与税額はどれか",
        "important": ""
    },
    {
        "id": 82,
        "shikenId": 4,
        "no": 22,
        "answerType": 9,
        "groupId": 1,
        "answer": 595,
        "score": 2,
        "question1": "杉田家のキャッシュフロー表の空欄（ア）にあてはまる数値を計算しなさい。なお、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src='/img/2_82.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01改",
        "categoryName": "ライフ",
        "rontenName": "CF表",
        "important": ""
    },
    {
        "id": 83,
        "shikenId": 4,
        "no": 23,
        "answerType": 9,
        "groupId": 1,
        "answer": 729,
        "score": 2,
        "question1": "杉田家のキャッシュフロー表の空欄（イ）にあてはまる数値を計算しなさい。なお、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src='/img/2_82.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01改",
        "categoryName": "ライフ",
        "rontenName": "CF表",
        "important": ""
    },
    {
        "id": 84,
        "shikenId": 4,
        "no": 24,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "康人さんは、教育費の負担が心配になり、奨学金について調べることにした。日本学生支援機構の奨学金に関する次の記述のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "給付型奨学金の収入基準の判定は、申込人と父母の３人家族の場合、父母のどちらか収入の高い方１名を生計維持者として、判定を行う。",
        "choice2": "給付型奨学金の「予約採用」は、学力基準である「高等学校等における全履修科目の評定平均値が一定以上」という要件を満たしていない場合、申し込むことができない。",
        "choice3": "貸与型奨学金には、利息が付く「第一種」と利息が付かない「第二種」がある。",
        "choice4": "貸与型奨学金は、「第一種」と「第二種」を併用することができる。",
        "choice5": "2024.01改",
        "categoryName": "ライフ",
        "rontenName": "日本学生支援機構の奨学金",
        "important": ""
    },
    {
        "id": 85,
        "shikenId": 4,
        "no": 25,
        "answerType": 9,
        "groupId": 1,
        "answer": 477000,
        "score": 2,
        "question1": "<img src='/img/2_85.png'/><br>倉田さんは、自宅のリフォーム費用４５０万円をリフォームローンを利用して返済しようと考えている。今後１０年間、年利１.０％で毎年借入応当日に元利均等返済をする場合、毎年の返済額はいくらになるか。",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "ライフ",
        "rontenName": "6つの係数",
        "important": ""
    },
    {
        "id": 86,
        "shikenId": 4,
        "no": 26,
        "answerType": 9,
        "groupId": 1,
        "answer": 52855200,
        "score": 2,
        "question1": "<img src='/img/2_85.png'/><br>山本さんは、老後の生活資金として、毎年年末に２４０万円を受け取りたいと考えている。受取期間を２５年とし、年利１.０％で複利運用をした場合、受取り開始年の初めにいくらの資金があればよいか。",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "ライフ",
        "rontenName": "6つの係数",
        "important": ""
    },
    {
        "id": 87,
        "shikenId": 4,
        "no": 27,
        "answerType": 9,
        "groupId": 1,
        "answer": 496000,
        "score": 2,
        "question1": "<img src='/img/2_85.png'/><br>落合さんは、定年後の世界一周旅行の資金として、１５年後に８００万円を用意しようと考えている。年利１.０％で複利運用しながら毎年年末に一定額を積み立てる場合、毎年いくらずつ積み立てればよいか。",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "ライフ",
        "rontenName": "6つの係数",
        "important": ""
    },
    {
        "id": 88,
        "shikenId": 4,
        "no": 28,
        "answerType": 9,
        "groupId": 1,
        "answer": 1800,
        "score": 2,
        "question1": "<img src='/img/2_89.png'/><br>鶴見さん夫妻は、本年７月にマンションを購入する予定である。鶴見さん夫妻が＜設例＞のマンションを購入する場合の販売価格のうち、土地（敷地の共有持分）の価格を計算しなさい。なお、消費税の税率は１０％とし、計算結果については万円未満の端数が生じる場合は四捨五入すること。また、解答に当たっては、解答欄に記載されている単位に従うこと。",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "不動産",
        "rontenName": "土地（敷地の共有持分）の価格",
        "important": ""
    },
    {
        "id": 89,
        "shikenId": 4,
        "no": 29,
        "answerType": 7,
        "groupId": 1,
        "answer": "1,2,2,1",
        "score": "1,1,1,1",
        "question1": "<img src='/img/2_89.png'/><br>ＦＰの高倉さんは、個人に対する所得税の仕組みについて義博さんから質問を受けた。高倉さんが下記＜イメージ図＞を使用して行った所得税に関する次の（ア）～（エ）の説明のうち、適切なものには○、不適切なものには×を解答欄に入力しなさい。<br><img src='/img/2_90.png'/><br>",
        "question2": "",
        "choice1": "「義博さんが収入保障保険の保険料を支払ったことにより受けられる生命保険料控除は、所得控除として、一定金額を所得金額から差し引くことができます。」",
        "choice2": "「由紀恵さんがふるさと納税をしたことにより受けられる寄附金控除は、税額控除として、一定金額を所得税額から差し引くことができます。」",
        "choice3": "「義博さんが空き巣に入られ盗難被害を受けたことによって受けられる雑損控除は、税額控除として、一定金額を所得税額から差し引くことができます。」",
        "choice4": "「由紀恵さんがケガで入院し入院費を支払ったことにより受けられる医療費控除は、所得控除として、一定金額を所得金額から差し引くことができます。」",
        "choice5": "2023.05",
        "categoryName": "タックス",
        "rontenName": "所得税の仕組み",
        "important": ""
    },
    {
        "id": 90,
        "shikenId": 4,
        "no": 30,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "<img src='/img/2_89.png'/><br>義博さんは、生命保険の解約返戻金について、ＦＰの高倉さんに質問をした。高倉さんが、生命保険の解約返戻金相当額について説明する際に使用した下記の＜イメージ図＞のうち、一般的な低解約返戻金型終身保険の解約返戻金相当額の推移に係る図として、最も適切なものはどれか。",
        "question2": "",
        "choice1": "<img src='/img/2_91_1.png' class='img50' style='width:300px !important'/>",
        "choice2": "<img src='/img/2_91_2.png' class='img50' style='width:300px !important'/>",
        "choice3": "<img src='/img/2_91_3.png' class='img50' style='width:300px !important'/>",
        "choice4": "<img src='/img/2_91_4.png' class='img50' style='width:300px !important'/>",
        "choice5": "2023.05",
        "categoryName": "リスク",
        "rontenName": "解約返戻金相当額の推移に係る図",
        "important": ""
    },
    {
        "id": 91,
        "shikenId": 4,
        "no": 31,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "<img src='/img/2_89.png'/><br>義博さんは、契約中の収入保障保険Ａの保障額について、ＦＰの高倉さんに質問をした。高倉さんが説明の際に使用した下記＜イメージ図＞を基に、本年６月１日に義博さんが死亡した場合に支払われる年金総額として、正しいものはどれか。なお、年金は毎月受け取るものとする。<br><img src='/img/2_92.png'/>",
        "question2": "",
        "choice1": "９００万円",
        "choice2": "３,２４０万円",
        "choice3": "４,５００万円",
        "choice4": "５,４００万円",
        "choice5": "2023.05改",
        "categoryName": "リスク",
        "rontenName": "収入保障保険の保障額",
        "important": ""
    },
    {
        "id": 92,
        "shikenId": 4,
        "no": 32,
        "answerType": 8,
        "groupId": 1,
        "answer": "4,1,8,10",
        "score": "1,1,1,1",
        "question1": "<img src='/img/2_89.png'/><br>由紀恵さんは、義博さんが万一死亡した場合の公的年金の遺族給付について、ＦＰの高倉さんに相談をした。義博さんが、本年６月に３５歳で在職中に死亡した場合に、由紀恵さんが受け取ることができる遺族給付を示した下記＜イメージ図＞の空欄（ア）〜（エ）に入る適切な語句を語群の中から選び、その番号のみを解答欄に入力しなさい。なお、義博さんは、２０歳から大学卒業まで国民年金に加入し、大学卒業後の２２歳から死亡時まで継続して厚生年金保険に加入しているものとする。また、家族に障害者に該当する者はなく、記載以外の遺族給付の受給要件はすべて満たしているものとする。<br><img src='/img/2_93.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8|9|10",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "ライフ",
        "rontenName": "公的年金の遺族給付",
        "important": ""
    },
    {
        "id": 93,
        "shikenId": 4,
        "no": 33,
        "answerType": 7,
        "groupId": 1,
        "answer": "2,2,1,1",
        "score": "1,1,1,1",
        "question1": "<img src='/img/2_89.png'/><br>義博さんの健康保険料に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。なお、義博さんは全国健康保険協会管掌健康保険（以下「協会けんぽ」という）の被保険者である。また、健康保険料の計算に当たっては、下記＜資料＞に基づくこととする。<br><img src='/img/2_94.png'/>",
        "question2": "",
        "choice1": "毎月の給与に係る健康保険料のうち、義博さんの負担分は１５,０００円である。",
        "choice2": "年２回支給される賞与に係る健康保険料については、義博さんの負担分はない。",
        "choice3": "義博さんが負担した健康保険料は、所得税の計算上、全額が社会保険料控除の対象となる。",
        "choice4": "協会けんぽの一般保険料率は都道府県支部単位で設定され、全国一律ではない。",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "健康保険料",
        "important": ""
    },
    {
        "id": 94,
        "shikenId": 4,
        "no": 34,
        "answerType": 8,
        "groupId": 1,
        "answer": "2,4,7",
        "score": "1,1,1",
        "question1": "<img src='/img/2_89.png'/><br>義博さんの母親の弘子さんは今年６５歳になる。義博さんは、弘子さんの老齢基礎年金の繰下げ受給についてＦＰの高倉さんに質問をした。老齢基礎年金の繰下げ受給に関する次の記述の空欄（ア）〜（ウ）に入る適切な語句を語群の中から選び、その番号のみを解答欄に入力しなさい。<br><img src='/img/2_95.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金の繰下げ受給",
        "important": ""
    },
    {
        "id": 95,
        "shikenId": 4,
        "no": 35,
        "answerType": 9,
        "groupId": 1,
        "answer": 12550,
        "score": 2,
        "question1": "<img src='/img/2_96_1.png'/><br><img src='/img/2_96_2.png'/><br>ＦＰの最上さんは、まず本年１月１日現在における池谷家（雅之さんと博子さん）のバランスシート分析を行うこととした。下表の空欄（ア）にあてはまる数値を計算しなさい。<br><img src='/img/2_96_3.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01改",
        "categoryName": "ライフ",
        "rontenName": "バランスシート",
        "important": ""
    },
    {
        "id": 96,
        "shikenId": 4,
        "no": 36,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "<img src='/img/2_96_1.png'/><br><img src='/img/2_96_2.png'/><br>博子さんは、昨年８月末に正社員として勤務していたＲＸ株式会社を退職し、その後再就職はしていない。退職後、ＲＸ株式会社から交付された源泉徴収票（一部省略）は下記＜資料＞のとおりである。雅之さんの昨年分の所得税の計算において、適用を受けることのできる配偶者特別控除の額として、正しいものはどれか。なお、雅之さんの昨年分の所得金額は９００万円以下であるものとする。また、博子さんには、ＲＸ株式会社からの給与以外に申告すべき所得はない。<br><img src='/img/2_96_4.png'/><br><img src='/img/2_96_5.png'/>",
        "question2": "",
        "choice1": "０円",
        "choice2": "１１万円",
        "choice3": "３１万円",
        "choice4": "３８万円",
        "choice5": "2024.01改",
        "categoryName": "タックス",
        "rontenName": "配偶者特別控除の額",
        "important": ""
    },
    {
        "id": 97,
        "shikenId": 4,
        "no": 37,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "<img src='/img/2_96_1.png'/><br><img src='/img/2_96_2.png'/><br>雅之さんは、２０ＸＸ年１０月に購入した国内公募追加型株式投資信託ＲＲファンドの売却を検討している。下記＜資料＞に基づき、ＲＲファンドを一部解約した場合の譲渡所得の金額として、正しいものはどれか。なお、解答に当たっては、円未満の端数が生じた場合には、円未満の端数を切り捨てること。<br><img src='/img/2_97.png'/>",
        "question2": "",
        "choice1": "４８,９８４円",
        "choice2": "５８,０９０円",
        "choice3": "７２,６１２円",
        "choice4": "９６,２４０円",
        "choice5": "2024.01改",
        "categoryName": "タックス",
        "rontenName": "投資信託の売却、譲渡所得の金額",
        "important": ""
    },
    {
        "id": 98,
        "shikenId": 4,
        "no": 38,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "<img src='/img/2_96_1.png'/><br><img src='/img/2_96_2.png'/><br>雅之さんが２０Ｘ１年から２０Ｘ３年の間に行った国内公募追加型株式投資信託ＲＱファンドの取引は、下記＜資料＞のとおりである。２０Ｘ３年末時点におけるＲＱファンドの個別元本（１万口当たり）として、正しいものはどれか。なお、記載のない事項については一切考慮しないものとする。<br><img src='/img/2_98.png'/>",
        "question2": "",
        "choice1": "１０,５００円",
        "choice2": "１０,７３１円",
        "choice3": "１１,０００円",
        "choice4": "１１,２４２円",
        "choice5": "2024.01",
        "categoryName": "金融",
        "rontenName": "投資信託の個別元本",
        "important": ""
    },
    {
        "id": 99,
        "shikenId": 4,
        "no": 39,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "<img src='/img/2_96_1.png'/><br><img src='/img/2_96_2.png'/><br>雅之さんは、現在の勤務先で、６０歳の定年を迎えた後も継続雇用制度を利用し、厚生年金保険に加入しつつ７０歳まで働き続ける場合の在職老齢年金について、ＦＰの最上さんに質問をした。下記＜資料＞に基づく条件で支給調整された老齢厚生年金の受給額（年額）として、正しいものはどれか。<br><img src='/img/2_99.png'/>",
        "question2": "",
        "choice1": "５４０,０００円",
        "choice2": "６６０,０００円",
        "choice3": "９３０,０００円",
        "choice4": "１,０５０,０００円",
        "choice5": "2024.01改",
        "categoryName": "ライフ",
        "rontenName": "在職老齢年金",
        "important": ""
    },
    {
        "id": 100,
        "shikenId": 4,
        "no": 40,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "<img src='/img/2_96_1.png'/><br><img src='/img/2_96_2.png'/><br>博子さんは、現在、雅之さんが加入する全国健康保険協会管掌健康保険（協会けんぽ）の被扶養者となっている。今後、博子さんがパートタイマーとして地元の中小企業ＰＥ株式会社で働き始めた場合でも、引き続き雅之さんが加入する健康保険の被扶養者となるための条件について、ＦＰの最上さんに質問をした。健康保険の被保険者および被扶養者に関する次の説明の空欄（ア）～（ウ）にあてはまる語句または数値の組み合わせとして、最も適切なものはどれか。なお、ＰＥ株式会社の従業員数は５０人以下であり、任意特定適用事業所ではないものとする。また、問題作成の都合上、一部を「＊＊＊」にしてある。<br><img src='/img/2_100.png'/>",
        "question2": "",
        "choice1": "（ア）３分の２ （イ）１０３ （ウ）２分の１",
        "choice2": "（ア）３分の２ （イ）１３０ （ウ）３割",
        "choice3": "（ア）４分の３ （イ）１０３ （ウ）３割",
        "choice4": "（ア）４分の３ （イ）１３０ （ウ）２分の１",
        "choice5": "2024.01改",
        "categoryName": "ライフ",
        "rontenName": "健康保険の被扶養者",
        "important": ""
    },
    {
        "id": 101,
        "shikenId": 2,
        "no": 1,
        "answerType": 4,
        "groupId": 0,
        "answer": "816000,1189219",
        "score": "1,2",
        "question1": "<img src='/img/3_101.png'/>",
        "question2": "Ａさんが、原則として65歳から受給することができる公的年金制度からの老齢給付について、次の①、②を求め、解答欄に入力しなさい（計算過程の記載は不要）。計算にあたっては、《設例》の＜Ａさんとその家族に関する資料＞および下記の＜資料＞に基づくこと。なお、年金額は2024年度価額に基づいて計算し、年金額の端数処理は円未満を四捨五入すること。 <br>① 老齢基礎年金の年金額<br>② 老齢厚生年金の年金額<br><img src='/img/3_101_2.png'/>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "公的年金制度からの老齢給付",
        "important": ""
    },
    {
        "id": 102,
        "shikenId": 2,
        "no": 2,
        "answerType": 6,
        "groupId": 0,
        "answer": "チ,ル,ロ,ニ",
        "score": "1,1,1,1",
        "question1": "<img src='/img/3_101.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、確定拠出年金の個人型年金（以下、「個人型年金」という）について説明した。Ｍさんが説明した以下の文章の空欄①～④に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><img src='/img/3_102_2.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ|ル|ヲ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "確定拠出年金の個人型年金",
        "important": ""
    },
    {
        "id": 103,
        "shikenId": 2,
        "no": 3,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,2",
        "score": "1,1,1",
        "question1": "<img src='/img/3_101.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、公的年金制度等についてアドバイスをした。Ｍさんがアドバイスした次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ａさんが希望すれば、66歳以後、老齢基礎年金および老齢厚生年金の繰下げ支給の申出をすることができます。仮に、Ａさんが70歳で老齢基礎年金の繰下げ支給の申出をした場合、当該年金額の増額率は24％となります」<br>②「長女Ｃさんが、2025年11月以降の大学生である期間について国民年金の学生納付特例の適用を受ける場合、長女Ｃさん本人に係る所得要件はありますが、Ａさんおよび妻Ｂさんに係る所得要件はありません」<br>③「Ａさんが確定拠出年金の個人型年金の加入後に死亡した場合において、個人別管理資産があるときは、Ａさんの遺族は所定の手続により死亡一時金を受け取ることができます。Ａさんの遺族が受け取る死亡一時金は、所得税と相続税のいずれの課税対象にもなりません」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "公的年金制度等",
        "important": ""
    },
    {
        "id": 104,
        "shikenId": 2,
        "no": 4,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,2,1",
        "score": "1,1,1",
        "question1": "<img src='/img/3_104.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、《設例》のデータに基づいて、株式の投資指標について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ⅹ社およびＹ社のＲＯＥはいずれも８％を上回っています。一般に、ＲＯＥが高い会社ほど、自己資本の効率的な活用がなされていると判断することができます」<br>②「Ⅹ社株式およびＹ社株式のＰＢＲはいずれも１倍を下回っています。一般に、ＰＢＲが低いほど株価は割安と判断されますが、ＰＢＲが１倍を大きく下回る株式は、その企業の資本収益性や成長性に対する投資家の評価が低い可能性があります」<br>③「配当性向は、Ⅹ社のほうがＹ社よりも高くなっています。一般に、配当性向が高いほど、株主への利益還元の度合いが高いと考えることができます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "金融",
        "rontenName": "株式の投資指標",
        "important": ""
    },
    {
        "id": 105,
        "shikenId": 2,
        "no": 5,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,2",
        "score": "1,1,1",
        "question1": "<img src='/img/3_104.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、株式および債券の売買等に係る税金について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ａさんが特定口座（源泉徴収あり）においてＸ社株式を株価1,500円で500株購入し、購入した年に株価1,700円で全株売却する場合、その他の取引や手数料等を考慮しなければ、売却益となる10万円の20.315％相当額が源泉徴収等されます」<br>②「ＡさんがⅩ社株式やＹ社株式を購入して配当金の支払を受けた場合、その配当金について、申告分離課税を選択して所得税の確定申告をすることにより、配当控除の適用を受けることができます」<br>③「Ｚ社債の利子は、源泉分離課税の対象となり、その支払を受ける際に当該利子額の20.315％相当額が源泉徴収等されることで納税が完結するため、Ⅹ社株式やＹ社株式などの上場株式の譲渡損失の金額と損益通算することはできません」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "金融",
        "rontenName": "株式・債券の売買等に係る税金",
        "important": ""
    },
    {
        "id": 106,
        "shikenId": 2,
        "no": 6,
        "answerType": 4,
        "groupId": 0,
        "answer": "0.73,0.86",
        "score": "2,2",
        "question1": "<img src='/img/3_104.png'/>",
        "question2": "Ｚ社債を《設例》の〈Ｚ社債に関する資料〉に基づいて購入した場合において、次の①、②をそれぞれ求め、解答欄に入力しなさい（計算過程の記載は不要）。なお、〈答〉は、表示単位の小数点以下第３位を四捨五入し、小数点以下第２位までを解答すること。また、税金等は考慮しないものとする。<br>①Ｚ社債を償還まで保有した場合の最終利回り（年率・単利）<br>②Ｚ社債を２年後に額面100円当たり99.90円で売却した場合の所有期間利回り（年率・単利）",
        "choice1": "％",
        "choice2": "％",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "金融",
        "rontenName": "債券の利回り",
        "important": ""
    },
    {
        "id": 107,
        "shikenId": 2,
        "no": 7,
        "answerType": 6,
        "groupId": 0,
        "answer": "チ,ニ,イ",
        "score": "1,1,1",
        "question1": "<img src='/img/3_107.png'/>",
        "question2": "所得税における青色申告制度に関する以下の文章の空欄①～③に入る最も適切な数値を、下記の〈数値群〉のなかから選び、その記号を解答欄に入力しなさい。<br><img src='/img/3_107_2.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "タックス",
        "rontenName": "所得税における青色申告制度",
        "important": ""
    },
    {
        "id": 108,
        "shikenId": 2,
        "no": 8,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,2",
        "score": "1,1,1",
        "question1": "<img src='/img/3_107.png'/>",
        "question2": "Ａさんの本年分の所得税の課税に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「不動産所得の金額の計算上生じた損失の金額のうち、土地の取得に係る負債の利子20万円に相当する部分の金額は、他の所得の金額と損益通算することはできません」<br>②「妻Ｂさんの合計所得金額は48万円以下であるため、Ａさんは配偶者控除の適用を受けることができます」<br>③「母Ｃさんは75歳未満であるため、老人扶養親族には該当せず、一般の控除対象扶養親族に該当します。母Ｃさんに係る扶養控除の控除額は、38万円です」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "タックス",
        "rontenName": "所得税の課税",
        "important": ""
    },
    {
        "id": 109,
        "shikenId": 2,
        "no": 9,
        "answerType": 4,
        "groupId": 0,
        "answer": "4850000,480000,172500",
        "score": "1,1,2",
        "question1": "<img src='/img/3_107.png'/>",
        "question2": "Ａさんの本年分の所得税の算出税額を計算した下記の表の空欄①～③に入る最も適切な数値を求めなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/3_109.png'/>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "円",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "タックス",
        "rontenName": "所得税の算出税額",
        "important": ""
    },
    {
        "id": 110,
        "shikenId": 2,
        "no": 10,
        "answerType": 6,
        "groupId": 0,
        "answer": "ロ,ニ,チ",
        "score": "1,1,1",
        "question1": "<img src='/img/3_110.png'/>",
        "question2": "「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」（以下、「本特例」という）に関する以下の文章の空欄①～③に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br>「本特例の適用を受けるためには、相続した家屋について、（①）年５月31日以前に建築されたこと、相続開始直前において被相続人以外に居住をしていた人がいなかったことなどの要件を満たす必要があり、マンションなどの区分所有建物登記がされている建物（②）。<br>本特例の適用を受けるためには、家屋を取り壊して更地で譲渡するか、または、家屋を一定の耐震基準を満たすようにリフォームしてから、その家屋のみを譲渡するか、もしくはその家屋とともに敷地を譲渡する必要があります。ただし、いずれの場合であっても、その譲渡の対価の額が（③）以下でなければなりません」<br><img src='/img/3_110_2.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "不動産",
        "rontenName": "空き家に係る譲渡所得の特別控除の特例",
        "important": ""
    },
    {
        "id": 111,
        "shikenId": 2,
        "no": 11,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,1,1",
        "score": "1,1,1",
        "question1": "<img src='/img/3_110.png'/>",
        "question2": "建設協力金方式の一般的な特徴等に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「建設協力金方式とは、ＡさんがＺ社から建設資金を借り受けて、Ｚ社の要望に沿った店舗を建設し、その店舗をＺ社に賃貸する手法です。借り受けた建設資金は、通常、賃料の一部で返済していくことになります」<br>②「建設協力金方式により、Ａさんが店舗をＺ社に賃貸した後、その賃貸期間中にＡさんの相続が開始した場合、相続税額の計算上、店舗は貸家として評価され、甲土地は貸家建付地として評価されます」<br>③「建設協力金方式により、Ａさんが店舗をＺ社に賃貸した後、その賃貸期間中にＡさんの相続が開始した場合、所定の要件を満たせば、甲土地は、貸付事業用宅地等として『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けることができます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "不動産",
        "rontenName": "建設協力金方式の一般的な特徴等",
        "important": ""
    },
    {
        "id": 112,
        "shikenId": 2,
        "no": 12,
        "answerType": 4,
        "groupId": 0,
        "answer": "480,1440",
        "score": "2,2",
        "question1": "<img src='/img/3_110.png'/>",
        "question2": "甲土地上に準耐火建築物を建築する場合における次の①、②を求めなさい（計算過程の記載は不要）。<br>①建蔽率の上限となる建築面積<br>②容積率の上限となる延べ面積",
        "choice1": "㎡",
        "choice2": "㎡",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "不動産",
        "rontenName": "建築面積と延べ面積の上限",
        "important": ""
    },
    {
        "id": 113,
        "shikenId": 2,
        "no": 13,
        "answerType": 6,
        "groupId": 0,
        "answer": "ホ,ロ,リ,チ",
        "score": "1,1,1,1",
        "question1": "<img src='/img/3_113.png'/>",
        "question2": "Ａさんの相続・事業承継等に関する以下の文章の空欄①～④に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><img src='/img/3_113_2.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "相続",
        "rontenName": "相続・事業承継等に関する語句または数値",
        "important": ""
    },
    {
        "id": 114,
        "shikenId": 2,
        "no": 14,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,2",
        "score": "1,1,1",
        "question1": "<img src='/img/3_113.png'/>",
        "question2": "孫ＥさんがＡさんから「直系尊属から教育資金の一括贈与を受けた場合の贈与税の非課税」（以下、「本制度」という）の適用を受けて教育資金の贈与を受けた場合に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「孫Ｅさんが本制度の適用を受けて取得した教育資金は、1,500万円を限度に贈与税が非課税となります。ただし、学習塾などの学校等以外の者に対して直接支払われる金銭については500万円が限度となります」<br>②「Ａさんが教育資金管理契約の終了の日までに死亡した場合において、Ａさんの相続に係る相続税の課税価格の合計額が１億円を超えるときは、孫Ｅさんは、その年齢にかかわらず、非課税拠出額から教育資金支出額を控除した残額を相続により取得したものとみなされます」<br>③「受贈者である孫Ｅさんが23歳に達すると、教育資金管理契約は終了します。その場合、非課税拠出額から教育資金支出額を控除した残額は、孫Ｅさんが23歳に達した日の属する年の贈与税の課税価格に算入されます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "相続",
        "rontenName": "教育資金の一括贈与の贈与税の非課税",
        "important": ""
    },
    {
        "id": 115,
        "shikenId": 2,
        "no": 15,
        "answerType": 4,
        "groupId": 0,
        "answer": "4800,1025,4950",
        "score": "1,1,1",
        "question1": "<img src='/img/3_113.png'/>",
        "question2": "現時点（2024年５月26日）において、Ａさんの相続が開始した場合における相続税の総額を試算した下記の表の空欄①～③に入る最も適切な数値を求めなさい。なお、課税遺産総額（相続税の課税価格の合計額－遺産に係る基礎控除額）は２億3,000万円とし、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/3_115.png'/>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "相続",
        "rontenName": "相続税の総額",
        "important": ""
    },
    {
        "id": 116,
        "shikenId": 3,
        "no": 1,
        "answerType": 4,
        "groupId": 0,
        "answer": "763300,322479,20",
        "score": "1,1,1",
        "question1": "<img src='/img/4_116.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、Ａさんが受給することができる公的年金制度からの老齢給付について説明した。Ｍさんが説明した以下の文章の空欄①～③に入る最も適切な数値を、解答欄に入力しなさい。年金額の計算にあたっては、《設例》の〈Ａさんとその家族に関する資料〉および下記の〈資料〉に基づくこと。なお、年金額は2024年度価額に基づいて計算し、年金額の端数処理は円未満を四捨五入すること。<br><br>Ⅰ「Ａさんが65歳に達すると、老齢基礎年金および老齢厚生年金の受給権が発生します。Ａさんが65歳から受給することができる老齢基礎年金の額は、（①）円となります」<br>Ⅱ「Ａさんが65歳から受給することができる老齢厚生年金の額は、（②）円となります。なお、Ａさんの厚生年金保険の被保険者期間は（③）年以上ありませんので、老齢厚生年金の額に配偶者に係る加給年金額の加算はありません」<br><img src='/img/4_116_2.png'/>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "年",
        "choice4": "",
        "choice5": "2024.05改",
        "categoryName": "ライフ",
        "rontenName": "公的年金制度からの老齢給付",
        "important": ""
    },
    {
        "id": 117,
        "shikenId": 3,
        "no": 2,
        "answerType": 6,
        "groupId": 0,
        "answer": "ロ,ホ,リ",
        "score": "1,1,1",
        "question1": "<img src='/img/4_116.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、小規模企業共済制度について説明した。Ｍさんが説明した以下の文章の空欄①～③に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br>「小規模企業共済制度は、Ａさんのような個人事業主が廃業等した場合に必要となる資金を準備しておくための共済制度です。毎月の掛金は、1,000円から（①）円までの範囲内で、500円単位で選択することができます。支払った掛金は、税法上、（②）の対象となります。<br>共済金は、加入者に廃業等の事由が生じた場合に、掛金納付月数等に応じて支払われます。共済金の受取方法には、『一括受取り』『分割受取り』『一括受取りと分割受取りの併用』があります。『一括受取り』の共済金（死亡事由以外）は、税法上、（③）として課税対象となります。<br>なお、掛金納付月数が240カ月未満で任意解約した場合は、解約手当金の額が掛金合計額を下回りますので、早期の解約はお勧めできません」<br><img src='/img/4_117.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05改",
        "categoryName": "ライフ",
        "rontenName": "小規模企業共済制度",
        "important": ""
    },
    {
        "id": 118,
        "shikenId": 3,
        "no": 3,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,1,2",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_116.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、老後の収入を増やすことができる各種制度について説明した。Ｍさんが説明した次の記述①～④について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ａさんは、国民年金の定額保険料に上乗せして、付加保険料を納付することができます。仮に、Ａさんが付加保険料を200月納付し、65歳から老齢基礎年金を受給する場合、老齢基礎年金の額に付加年金として年額80,000円が上乗せされます」<br>②「国民年金基金は、老齢基礎年金に上乗せする年金を支給する任意加入の年金制度です。加入は口数制となっており、１口目は２種類の終身年金（Ａ型・Ｂ型）のいずれかを選択します」<br>③「国民年金基金の１口目の給付には、国民年金の付加年金相当が含まれているため、Ａさんが国民年金基金に加入した場合、国民年金の付加保険料を納付することはできません」<br>④「国民年金の定額保険料や付加保険料を前納した場合、前納期間に応じて保険料の割引がありますが、国民年金基金の掛金については、前納による割引制度はありません」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05改",
        "categoryName": "ライフ",
        "rontenName": "老後の収入を増やす各種制度",
        "important": ""
    },
    {
        "id": 119,
        "shikenId": 3,
        "no": 4,
        "answerType": 4,
        "groupId": 0,
        "answer": "100,1500",
        "score": "2,1",
        "question1": "<img src='/img/4_119.png'/>",
        "question2": "はじめに、Ｍさんは、Ａさんに対して、必要保障額および現在加入している定期保険特約付終身保険の保障金額について説明した。Ｍさんが説明した以下の文章の空欄①、②に入る最も適切な数値を解答欄に入力しなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/4_119_2.png'/>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "保険（個人）",
        "rontenName": "必要保障額と現在の保障金額",
        "important": ""
    },
    {
        "id": 120,
        "shikenId": 3,
        "no": 5,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,1,2",
        "score": "1,1,1",
        "question1": "<img src='/img/4_119.png'/>",
        "question2": "次に、Ｍさんは、Ａさんに対して、公的介護保険について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「公的介護保険の被保険者は、65歳以上の第１号被保険者と40歳以上65歳未満の医療保険加入者である第２号被保険者に区分されます。保険給付を受けるためには、市町村（特別区を含む）から要介護認定または要支援認定を受ける必要があります」<br>②「公的介護保険の第２号被保険者が保険給付を受けられるのは、特定疾病が原因で要介護状態または要支援状態となった場合に限られていますが、第１号被保険者は、要介護状態または要支援状態となった原因を問わず、保険給付を受けることができます」<br>③「公的介護保険の第１号被保険者が保険給付を受けた場合の自己負担割合は、合計所得金額の多寡にかかわらず、実際にかかった費用（食費、居住費等を除く）の１割となります」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "保険（個人）",
        "rontenName": "公的介護保険",
        "important": ""
    },
    {
        "id": 121,
        "shikenId": 3,
        "no": 6,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,2,1",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_119.png'/>",
        "question2": "最後に、Ｍさんは、Ａさんに対して、Ａさんが現在加入している生命保険の見直し等について説明した。Ｍさんが説明した次の記述①～④について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「現在加入している定期保険特約付終身保険の定期保険特約は、65歳の主契約の保険料払込期間満了後も更新することができますが、更新後の保険料は大幅に上昇します。支出可能な保険料の額を踏まえたうえで、無理のない範囲内で見直しを行ってください」<br>②「現在加入している終身保険を残し、その他の特約を減額または解約して、介護保険に新規加入する方法が考えられます。公的介護保険の給付は、主に訪問介護や通所介護（デイサービス）などの現物給付による介護サービスであるため、一定額の介護年金および介護一時金を準備することは検討に値すると思います」<br>③「現在加入している定期保険特約付終身保険を見直す方法として、契約転換制度の活用が考えられます。契約転換時の告知や医師の診査は不要で、健康状態にかかわらず、保障内容を見直すことができます」<br>④「現在加入している定期保険特約付終身保険を契約転換して、同じ生命保険会社の介護保障に特化した保険に加入した場合、現在加入している終身保険の保障は消滅します。契約転換制度を利用する際は、転換前・転換後契約の保障内容等を比較して、総合的に判断してください」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "保険（個人）",
        "rontenName": "生命保険の見直し",
        "important": ""
    },
    {
        "id": 122,
        "shikenId": 3,
        "no": 7,
        "answerType": 4,
        "groupId": 0,
        "answer": "1990,2005",
        "score": "1,2",
        "question1": "<img src='/img/4_122.png'/>",
        "question2": "仮に、Ｘ社がＡさんに役員退職金6,000万円を支給した場合、Ａさんが受け取る役員退職金について、次の①、②を求め、解答欄に入力しなさい（計算過程の記載は不要）。〈答〉は万円単位とすること。なお、Ａさんの役員在任期間（勤続年数）を36年９カ月とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。<br>①退職所得控除額<br>②退職所得の金額",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "保険（法人）",
        "rontenName": "退職所得の計算",
        "important": ""
    },
    {
        "id": 123,
        "shikenId": 3,
        "no": 8,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,2,2",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_122.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、＜資料＞の長期平準定期保険について説明した。Ｍさんが説明した次の記述①～④について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「当該生命保険を現時点で解約した場合、Ｘ社はそれまで資産計上していた前払保険料6,600万円を取り崩して、解約返戻金5,200万円との差額1,400万円を雑損失として経理処理します」<br>②「Ａさんの勇退時に当該生命保険を払済終身保険に変更し、契約者をＡさん、死亡保険金受取人をＡさんの相続人に名義を変更することで、Ｘ社は終身保険契約を役員退職金の一部としてＡさんに現物支給することができます」<br>③「当該生命保険を現時点で払済終身保険に変更した場合、変更した事業年度において雑損失が計上されます。したがって、変更した事業年度の利益を減少させる効果があります」<br>④「Ｘ社が契約者貸付制度を利用し、契約者貸付金を受け取った場合、その全額を雑収入として益金の額に算入します」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "保険（法人）",
        "rontenName": "長期平準定期保険",
        "important": ""
    },
    {
        "id": 124,
        "shikenId": 3,
        "no": 9,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,2",
        "score": "1,1,1",
        "question1": "<img src='/img/4_122.png'/>",
        "question2": "Ｍさんは、長男Ｂさんに対して、生命保険の活用方法について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「経営者が要介護状態あるいは重度の疾患等で長期間不在となった場合、業績が悪化してしまう可能性も考えられます。そのため、長男Ｂさんが重い病気等になった場合にＸ社が一時金を受け取ることができる生前給付タイプの生命保険に加入されることも検討事項の１つとなります」<br>②「保険期間10年の定期保険は、長期平準定期保険に比べて保険料が割安なうえ、保険期間の途中で解約することで、多額の解約返戻金を受け取ることができるため、長男Ｂさんの役員（生存）退職金を準備する方法として適しています」<br>③「役員・従業員の退職金準備のために、養老保険の福利厚生プランを活用する方法があります。契約者をＸ社、被保険者を役員・従業員全員、死亡保険金受取人を役員・従業員の遺族、満期保険金受取人をＸ社とする養老保険に加入することにより、支払保険料の全額を福利厚生費として損金の額に算入することができます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "保険（法人）",
        "rontenName": "生命保険の活用方法",
        "important": ""
    },
    {
        "id": 125,
        "shikenId": 3,
        "no": 10,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,1,2",
        "score": "1,1,1",
        "question1": "<img src='/img/4_125.png'/>",
        "question2": "Ａさんの本年分の所得税の課税に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「不動産所得の金額の計算上生じた損失の金額のうち、当該不動産所得を生ずべき土地の取得に係る負債の利子20万円に相当する部分の金額は、他の所得の金額と損益通算することはできません」<br>②「Ａさんが受け取った一時払変額個人年金保険の解約返戻金は、一時所得の収入金額として総合課税の対象になります」<br>③「Ａさんが本年分の所得税の確定申告をするときに、納税地の所轄税務署長に青色申告承認申請書を提出すれば、本年分の総所得金額から、青色申告特別控除額を控除することができます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "タックス",
        "rontenName": "所得税の課税",
        "important": ""
    },
    {
        "id": 126,
        "shikenId": 3,
        "no": 11,
        "answerType": 6,
        "groupId": 0,
        "answer": "ホ,ト,ニ",
        "score": "1,1,1",
        "question1": "<img src='/img/4_125.png'/>",
        "question2": "Ａさんの本年分の所得税における所得控除に関する以下の文章の空欄①～③に入る最も適切な数値を、下記の〈数値群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br><img src='/img/4_126.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "タックス",
        "rontenName": "所得控除に関する数値",
        "important": ""
    },
    {
        "id": 127,
        "shikenId": 3,
        "no": 12,
        "answerType": 4,
        "groupId": 0,
        "answer": "6150000,40000,480000,242500",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_125.png'/>",
        "question2": "Ａさんの本年分の所得税の算出税額を計算した下記の表の空欄①～④に入る最も適切な数値を求めなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/4_127.png'/>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "円",
        "choice4": "円",
        "choice5": "2023.05改",
        "categoryName": "タックス",
        "rontenName": "所得税の算出税額",
        "important": ""
    },
    {
        "id": 128,
        "shikenId": 3,
        "no": 13,
        "answerType": 4,
        "groupId": 0,
        "answer": "4800,5340,9220",
        "score": "1,1,1",
        "question1": "<img src='/img/4_128.png'/>",
        "question2": "現時点（本年１月28日）において、Ａさんの相続が開始した場合における相続税の総額を試算した下記の表の空欄①～③に入る最も適切な数値を求めなさい。なお、相続税の課税価格の合計額は４億円とし、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/4_128_2.png'/>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "相続",
        "rontenName": "相続税の総額",
        "important": ""
    },
    {
        "id": 129,
        "shikenId": 3,
        "no": 14,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,2,1",
        "score": "1,1,1",
        "question1": "<img src='/img/4_128.png'/>",
        "question2": "Ａさんの相続に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「遺産分割をめぐる争いを防ぐ手段として、遺言書の作成をお勧めします。自筆証書遺言については、法務局における保管制度がありますが、当該制度を利用するためには証人２人以上の立会いが必要です」<br>②「遺言により、相続財産の大半を妻Ｂさんおよび長男Ｃさんが相続した場合、長女Ｄさんの遺留分を侵害するおそれがあります。仮に、遺留分を算定するための財産の価額が４億円である場合、長女Ｄさんの遺留分の金額は１億円となります」<br>③「妻Ｂさんが受け取る一時払終身保険の死亡保険金（2,000万円）は、みなし相続財産として相続税の課税対象となりますが、死亡保険金の非課税金額の規定の適用を受けることで、相続税の課税価格に算入される金額は、500万円となります」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "相続",
        "rontenName": "相続に関する記述",
        "important": ""
    },
    {
        "id": 130,
        "shikenId": 3,
        "no": 15,
        "answerType": 6,
        "groupId": 0,
        "answer": "ロ,ヘ,リ,ル",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_128.png'/>",
        "question2": "Ｘ屋の事業承継に関する以下の文章の空欄①～④に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br><img src='/img/4_130.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ|ル",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "相続",
        "rontenName": "事業承継に関する語句・数値",
        "important": ""
    }
]