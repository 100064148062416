
export default [
{
  "id": 1,
  "name": "ＣＢＴ体験版　学科試験",
  "title": "学科試験",
  "no": 1,
  "grade": 3,
  "time": 120
},
{
  "id": 2,
  "name": "ＣＢＴ体験版 実技試験（個人資産相談業務）",
  "title": "個人資産相談業務",
  "no": 2,
  "grade": 2,
  "time": 90
},
{
  "id": 3,
  "name": "ＣＢＴ体験版 実技試験（生保顧客資産相談業務）",
  "title": "生保顧客資産相談業務",
  "no": 2,
  "grade": 2,
  "time": 90
},
{
  "id": 4,
  "name": "ＣＢＴ体験版 実技試験（資産設計提案業務）",
  "title": "資産設計提案業務",
  "no": 2,
  "grade": 2,
  "time": 90
},
]